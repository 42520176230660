import React, { useEffect, useMemo } from 'react'

import { Layout } from '@/components/template/layout'
import { useAppSelector } from '@/app/hooks'
import { AccountInfo } from '@/features/user/account-slice'
import { QrCode } from '@/components/atoms/qr-code'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { CheckInAsset } from '@/components/organisms/checkIn/check-in-asset'
import { Button } from '@/components/atoms/button'
import { useNavigate } from 'react-router-dom'
import { isEmpty, omit } from 'lodash'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { Base64 } from 'js-base64'
import { ContentWrapper } from '@/components/template/content-wrapper'

export const CheckInAccept: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const navigate = useNavigate()

  const userData = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const checkInApproveData = useAppSelector(state => state.smartCheckIn.approvedCheckInData)
  const design = useAppSelector(state => state.design)

  const checkInQrCode = useMemo(() => {
    if (!checkInApproveData?.qrJson) {
      return ''
    }
    const qrJsonObj = omit(JSON.parse(checkInApproveData.qrJson), ['pass_id'])
    return Base64.encode(JSON.stringify(qrJsonObj))
  }, [checkInApproveData])

  useEffect(() => {
    if (isEmpty(checkInApproveData)) {
      return navigate('/')
    }
  })

  return (
    <Layout>
      <ContentWrapper>
        <div css={acceptBlockStyle}>
          <h2 css={[welcomeMessageStyle, { color: design.theme.brandColor }]}>Welcome</h2>
          {userData?.name && (
            <p css={nameStyle}>
              {userData.name}
              {language === 'ja' ? '様' : ''}
            </p>
          )}

          <div css={qrCodeBlockStyle}>
            <QrCode qrCode={checkInQrCode} />
            <p css={checkInIdText}>
              {t('Check-in ID')}:{checkInApproveData.checkinCode}
            </p>
          </div>

          <p style={{ fontSize: '12px', marginTop: '1.5rem' }}>{t('Check-in information has been sent to the registered destination')}</p>
          <p css={emailTextStyle}>{userData.email}</p>

          <CheckInAsset />
        </div>
      </ContentWrapper>

      <div css={acceptFooter} className={CUSTOM_BLOCK}>
        <ContentWrapper>
          <div className="info">
            <p className="only-page">{t('Guests only page')}</p>
            <p className="info-text">{t('Useful information during your stay!')}</p>
          </div>
          <Button className="button" onClick={() => navigate('/')} text={t('Check in advance')} />
        </ContentWrapper>
      </div>
    </Layout>
  )
}

const acceptBlockStyle = css({
  textAlign: 'center',
})

const welcomeMessageStyle = css({
  paddingTop: '1.5rem',
  marginBottom: '0.5rem',
  fontSize: '28px',
  fontWeight: 'bold',
})

const nameStyle = css({
  fontSize: '14px',
})

const qrCodeBlockStyle = css({
  width: '80%',
  padding: '2rem',
  borderRadius: '10px',
  margin: '1rem auto 1rem auto',
  background: '#fff',
})

const checkInIdText = css({
  fontWeight: 'bold',
  fontSize: '14px',
  marginTop: '1rem',
  color: '#272727',
})

const emailTextStyle = css({
  fontSize: '12px',
  marginTop: '0.5rem',
})

const acceptFooter = css({
  boxShadow: '0px 0px 10px #0000001F',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  '> div': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '14px 24px',
    '.only-page': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '0.25rem',
    },
    '.info-text': {
      fontSize: '12px',
    },
    '.button': {
      width: '134px',
      fontSize: '14px',
    },
  },
})
