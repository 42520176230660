import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'

import { fetchPaymentSetting } from '@/features/hotel/payment-setting-by-hotel'
import { StayReservation, getStayReservations } from '@/features/reservation/stay-reservation-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { Card } from '@/components/atoms/card'
import { spacerTopStyle } from '@/styles/common'
import { CheckBox } from '@/components/atoms/check-box'
import { Divider } from '@/components/atoms/divider'
import { ListItem } from '@/components/atoms/list-item'
import { createDateString } from '@/utils/date'
import { getValueByLanguage } from '@/libs/get-value-by-language'
import { LanguageType } from '@/i18n'
import { css } from '@emotion/react'
import { BorderButton, Button } from '@/components/atoms/button'
import { setCheckoutReservations } from '@/features/checkout/checkout-reservation-slice'
import { hasPaymentPlugin } from '@/features/hotel/hotel-guide-slice'
import { isEmpty } from 'lodash'
import { ReservationGuestRoomLabel } from '@/components/atoms/reservation-guest-room-label'
import { Loading } from '@/components/organisms/loading'
import dayjs from 'dayjs'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { minScreen } from '@/styles/media-query'

export const CheckoutReservationList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { hotelId, telephone } = useQuerySearchParams<{ hotelId: string; telephone: string }>()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const stayReservations = useAppSelector(state => state.reservation.stayReservation.stayReservations)
  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const hotelGuideData = useAppSelector(state => state.hotelGuide)

  const setCheckInsOrReservations = async () => {
    try {
      dispatch(getStayReservations({ hotelId, telephone }))
      await dispatch(fetchPaymentSetting(hotelId))
    } catch (error) {
      navigate('/checkout')
    }
  }

  useLayoutEffect(() => {
    if (!telephone) {
      navigate('/checkout')
    }

    setLoading(true)
    dispatch(hasPaymentPlugin())
    setCheckInsOrReservations()
    setLoading(false)
  }, [])

  const [selectedItems, setSelectedItems] = useState<StayReservation[]>([])

  const onSubmit = () => {
    const paymentMethodValues: string[] = []
    const checkoutPaymentSetting = paymentSetting?.payment_time?.find(item => item.value === 'CHECKOUT')

    if (checkoutPaymentSetting && checkoutPaymentSetting.is_enabled) {
      for (let index = 0; index < checkoutPaymentSetting.payment_method.length; index++) {
        const paymentMethod = checkoutPaymentSetting.payment_method[index]
        if (paymentMethod.is_enabled) {
          if (paymentMethod.value === 'DIRECT') {
            paymentMethodValues.push('cash')
          } else if (paymentMethod.value === 'CREDIT_CARD') {
            paymentMethodValues.push('creditCard')
          }
        }
      }
    }

    dispatch(setCheckoutReservations(selectedItems))

    if (hotelGuideData.hasPaymentPlugins && paymentMethodValues.length) {
      navigate({ pathname: '/checkout/payment', search: `hotelId=${hotelId}&telephone=${telephone}` })
    } else {
      navigate({ pathname: '/checkout/review', search: `hotelId=${hotelId}&telephone=${telephone}` })
    }
  }

  useEffect(() => {
    setSelectedItems(stayReservations.filter(r => dayjs(r.checkoutDate).isSame(dayjs(), 'day')))
  }, [stayReservations])

  return (
    <Layout>
      <NavigateHeader title={t('Select guest Room')} />
      <Loading loading={loading}>
        <ContentWrapper>
          <div css={[spacerTopStyle, spaceInlineStyle]}>
            {stayReservations.map((stayReservation, index) => {
              return (
                <div key={index} css={index !== 0 ? spacerTopStyle : undefined}>
                  <Card cardCss={cardStyle}>
                    <CheckBox
                      label={<ReservationGuestRoomLabel roomNumbers={stayReservation.roomNumbers} />}
                      checked={
                        selectedItems.findIndex(
                          stayReservationItem => stayReservationItem.reservationId === stayReservation.reservationId,
                        ) !== -1
                      }
                      name={stayReservation.reservationId}
                      onChange={() => {
                        const findIndex = selectedItems.findIndex(
                          stayReservationItem => stayReservationItem.reservationId === stayReservation.reservationId,
                        )

                        if (findIndex !== -1) {
                          const newStayReservationItems = selectedItems.filter(
                            stayReservationItem => stayReservationItem.reservationId !== stayReservation.reservationId,
                          )

                          setSelectedItems(newStayReservationItems)
                        } else {
                          setSelectedItems([...selectedItems, stayReservation])
                        }
                      }}
                    />
                    <Divider customCss={{ marginLeft: '-1.5rem', marginRight: '-1.5rem', marginTop: '1rem' }} />

                    <div css={listItemBlockStyle}>
                      <ListItem
                        title={t('accommodation date')}
                        text={`${createDateString({ date: stayReservation.checkinDate, language })} ${stayReservation.nights} ${
                          stayReservation.nights === 1 ? t('night') : t('nights')
                        }`}
                      />

                      <ListItem
                        title={t('type of room')}
                        text={`${getValueByLanguage({ object: stayReservation, key: 'plan', lang: language as LanguageType })}`}
                      />

                      <ListItem title={t('Reservation ID')} text={stayReservation.reservationId} />
                    </div>
                  </Card>
                </div>
              )
            })}
          </div>

          <div css={[spaceInlineStyle, { marginTop: '2rem' }]}>
            <Button disabled={isEmpty(selectedItems)} text={t('Next')} onClick={() => onSubmit()} />
            <BorderButton
              buttonCss={borderButtonStyle}
              text={t('re-search')}
              onClick={() => navigate({ pathname: '/checkout', search: `hotelId=${hotelId}` })}
            />
          </div>
        </ContentWrapper>
      </Loading>
    </Layout>
  )
}

const cardStyle = css({
  paddingTop: '1rem',
  paddingBottom: '1rem',
})

const listItemBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  paddingTop: '1.5rem',
})

const borderButtonStyle = css({
  marginTop: '1rem',
})

const spaceInlineStyle = css({
  paddingInline: '1.5rem',
  [minScreen()]: {
    paddingInline: 0,
  },
})
