import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs, { Dayjs } from 'dayjs'

interface TranslateObject {
  ja: string
  en: string
  ko: string
  zh: string
  zhTw: string
}
export const SpacePriceType = {
  Charge: 'charge',
} as const
export type SpacePriceType = (typeof SpacePriceType)[keyof typeof SpacePriceType]
export interface SpaceChargePrice {
  usageTime: {
    start: string | undefined
    end: string | undefined
  }
  unitPrice: number | undefined
  maxPrice: number | undefined
}
export interface SpaceDetail {
  id: string
  title: TranslateObject
  description: TranslateObject
  question: TranslateObject
  images: string[]
  salesHour: {
    start: string
    end: string
  }
  spacePrice:
    | {
        type: SpacePriceType
        chargePrice: SpaceChargePrice
      }
    | undefined
  isFixAvailabilityTime: boolean
  availabilityTimes: { [key: string]: number }
  stock: number
  stockUnit: TranslateObject
  isFavorite: boolean
  hasRoomKeySetting: boolean
}

export interface ReservationForm {
  spaceId: string
  useDay: string
  quantity: number
  usageFromHour: string
  usageFromMinute: string
  usageToHour: string
  usageToMinute: string
  answer: string
  pax: number
  totalAmount: string | undefined
}

interface getSpaceAvailableTimeParams {
  hotelId: string
  guestAppAssetId: string
  useDay: Dayjs
}
export const getSpaceAvailableTime = createAsyncThunk(
  'space/getSpaceAvailableTime',
  async (params: getSpaceAvailableTimeParams, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/guest/spaces/available-time`,
        params: {
          ...params,
          hotel_id: params.hotelId,
          useDay: params.useDay.format('YYYY-MM-DD'),
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const formValuesStorageKey = 'spaces.formValues'
const restoreFormValues = (): ReservationForm | undefined => {
  const sessionData = sessionStorage.getItem(formValuesStorageKey)
  return sessionData ? JSON.parse(sessionData) : undefined
}

export const timeToMinutes = (time: string | undefined): number => {
  return time ? Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]) : 15
}

export const getStartAvailabilityTimes = (
  availabilityTimes: { [key: string]: number },
  quantity: number,
  minUsageMinute: number,
): string[] => {
  // 最小利用時間を満たさない開始時間をフィルタ
  const availabilityTimeByQuantity = Object.keys(availabilityTimes || {}).filter(time => availabilityTimes[time] >= quantity)
  const availabilityTimeByQuantityAndMinUsageMinute = availabilityTimeByQuantity.filter((time, index) => {
    const minUsageEndTime = dayjs(`2024-01-01 ${time}`)
      .add(minUsageMinute - 15, 'minute')
      .format('HH:mm')
    const intervalNum = (minUsageMinute - 15) / 15
    const endTimeIndex = availabilityTimeByQuantity.findIndex(time => time === minUsageEndTime)
    return endTimeIndex === index + intervalNum
  })
  return availabilityTimeByQuantityAndMinUsageMinute
}

const spacesSlice = createSlice({
  name: 'spaces',
  initialState: {
    spaceDetails: [] as SpaceDetail[],
    formValues: restoreFormValues(),
  },
  reducers: {
    storeFormValues: (state, action: PayloadAction<Partial<ReservationForm>>) => {
      state.formValues = {
        ...state.formValues,
        ...action.payload,
      } as ReservationForm
      sessionStorage.setItem(formValuesStorageKey, JSON.stringify(action.payload))
    },
    clearFormValues: state => {
      state.formValues = undefined
      sessionStorage.removeItem(formValuesStorageKey)
    },
  },
  extraReducers(builder) {
    builder.addCase(getSpaceAvailableTime.fulfilled, (state, action) => {
      state.spaceDetails = action.payload
    })
  },
})
export const { storeFormValues, clearFormValues } = spacesSlice.actions
export const spacesReducer = spacesSlice.reducer
