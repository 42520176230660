import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { Layout } from '../../template/layout'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { updateCheckInData } from '@/features/checkIn/check-in-slice'
import { SearchReservationInput, SearchReservationParams, SearchType, clearErrorMessage, schema, searchCheckIn } from '@/features/checkIn/search-reservations-slice'

import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { useHotelId } from '@/hooks/use-hotel-id'
import { useAppSelector } from '@/app/hooks'
import { Search } from '@/components/organisms/reservation/search'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export const ReservationSearch: React.FC = () => {
  const errorMessage = useAppSelector(state => state.checkIn.searchCheckIn.errorMessage)

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()
  const { getHotelId } = useHotelId()

  const dispatch = useDispatch<AppDispatch>()

  const navigate = useNavigate()

  const useFormReturn = useForm<SearchReservationInput>({
    resolver: zodResolver(schema)
  })

  const onSearch: SubmitHandler<SearchReservationInput> = async (submitData) => {
    if (submitData.searchType === SearchType.Phone) {
      dispatch(updateCheckInData({ reservationTel: submitData.reservationTel }))
    } else if (submitData.searchType === SearchType.ReservationId) {
      updateCheckInData({ reservationId: submitData.reservationId })
    } else if (submitData.searchType === SearchType.GuestName) {
      updateCheckInData({
        reservationGivenName: submitData.reservationGivenName,
        reservationFamilyName: submitData.reservationFamilyName,
      })
    }

    const params: SearchReservationParams = {
      hotelId: getHotelId() || hotelId,
      reservationTel: submitData.reservationTel,
      reservationId: submitData.reservationId,
      reservationGivenName: submitData.reservationGivenName,
      reservationFamilyName: submitData.reservationFamilyName,
    }

    const resultAction = await dispatch(searchCheckIn(params))

    if (resultAction.meta.requestStatus === 'fulfilled') {
      return navigate(`/checkin-reservation-confirm`)
    }

    if (searchCheckIn.rejected.match(resultAction)) {
      if (resultAction.payload?.errorMessage) {
        dispatch(toastAction.setMessage(MessageType.Error, resultAction.payload.errorMessage))
        return
      }
    }
  }

  useEffect(() => {
    if (errorMessage) {
      dispatch(toastAction.setMessage(MessageType.Error, errorMessage))
    }
    dispatch(clearErrorMessage())
  }, [])

  return (
    <Layout>
      <Search onSearch={onSearch} useFormReturn={useFormReturn} />
    </Layout>
  )
}
