import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { PaymentBlock } from '@/components/organisms/payment-block'
import { AmountBill, RoomBillDetail } from '@/components/organisms/room-bill-detail'
import { Layout } from '@/components/template/layout'
import { Payments, getReservationsPayments } from '@/features/checkout/checkout-payment-slice'
import { StayReservation, getStayReservations } from '@/features/reservation/stay-reservation-slice'
import { CardDetail, addCreditCard, cardSchema } from '@/features/user/user-card-slice'
import { horizontalSpacing, spacerTopStyle } from '@/styles/common'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { sendmailCheckoutPaymentInfo } from '@/features/checkout/send-mail-checkout-payment-info-slice'
import { postCheckoutInfo } from '@/features/reservation/post-checkout-info-slice'
import { backupReceiptPDF } from '@/features/reservation/reservation-slice'
import { Button } from '@/components/atoms/button'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Loading } from '@/components/organisms/loading'
import { errorHandler } from '@/libs/errors'
import { formatCardExpire } from '@/utils/creditCard/card-expire'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { creditCardPay } from '@/features/checkout/credit-card-pay-slice'
import { PaymentFormTemplate } from '@/components/template/payment-form-template'

export const CheckoutPayment: React.FC = () => {
  const [submitLoading, setSubmitLoading] = useState(false)

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { hotelId, telephone } = useQuerySearchParams<{ hotelId: string; telephone: string }>()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const stayReservations = useAppSelector(state => state.reservation.stayReservation.stayReservations)
  const checkoutReservations: StayReservation[] = useAppSelector(state => state.checkout.checkoutReservation.checkoutReservations)
  const { payments, loading: loadPaymentLoading } = useAppSelector(state => state.checkout.reservationsPayment)
  const userData = useAppSelector(state => state.accountInfo.user)
  const userCreditCard = useAppSelector(state => state.creditCard.userCreditCardData)
  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const hotelGuideData = useAppSelector(state => state.hotelGuide)

  // check has payment credit option
  const checkoutPaymentOption = paymentSetting?.payment_time?.find(v => v.is_enabled && v.value === 'CHECKOUT')

  const schema = z.discriminatedUnion('paymentType', [
    z.object({
      paymentType: z.literal('creditCard'),
      cardNumber: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardNumber,
      cardExpireYear: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireYear,
      cardExpireMonth: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireMonth,
      securityCode: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.securityCode,
      name: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.name,
      receiptName: cardSchema.shape.receiptName,
    }),
    z.object({
      paymentType: z.literal('checkout'),
    }),
  ])

  const createSchema = () => {
    if (!checkoutPaymentOption) {
      return undefined
    }

    return zodResolver(schema)
  }

  // For keep payment type when come back from login
  const selectedPaymentType = useAppSelector(state => state.userPayment.currentPayment.paymentType)

  const useFormReturn = useForm<CardDetail>({
    mode: 'all',
    defaultValues: {
      paymentType: selectedPaymentType,
    },
    resolver: createSchema(),
  })
  const {
    watch,
    handleSubmit,
    formState: { isValid },
  } = useFormReturn
  const watchPaymentType = watch('paymentType')

  const loadPayments = async () => {
    if (!checkoutReservations?.length) {
      return navigate({ pathname: '/checkout', search: `hotelId=${hotelId}&telephone=${telephone}` })
    }

    let paymentsResult = {} as Payments
    try {
      if (checkoutReservations?.length) {
        const result = await dispatch(
          getReservationsPayments({ hotelId, reservationIds: checkoutReservations?.map(reservation => reservation.reservationId) }),
        ).unwrap()
        paymentsResult = result
      }

      if (!paymentsResult?.totalAmount || paymentsResult?.totalAmount <= 0) {
        navigate({ pathname: '/checkout/review', search: `telephone=${telephone}` })
      }
    } catch (error) {
      errorHandler({ error })
    }
  }

  useEffect(() => {
    if (hotelId) {
      loadPayments()
    }
  }, [hotelId])

  useEffect(() => {
    dispatch(getStayReservations({ hotelId, telephone }))
  }, [])

  const afterPayment = async (paymentsResult, receiptName) => {
    try {
      const checkinIds = checkoutReservations?.map(reservation => reservation.checkinId)

      const checkoutInfoResult = await postCheckoutInfo({ orderId: paymentsResult.orderId, checkinIds, hotelId })
      const saleIds = checkoutInfoResult?.data?.saleIds

      const checkinIdsInfo: any[] = []

      for (const checkinId of checkinIds) {
        const reservationFilterList = stayReservations.filter(reservationItem => reservationItem.checkinId === checkinId)
        const reserIds = reservationFilterList.map(item => item.reservationId)

        const guestName = receiptName || (reservationFilterList.length > 0 ? reservationFilterList[0].guestName : '')

        checkinIdsInfo.push({
          checkinId,
          reserIds,
          saleIds,
          orderId: paymentsResult.orderId,
          guestName,
          paymentPage: 'checkout',
          provision: '',
        })
      }

      await dispatch(backupReceiptPDF({ hotelId, checkinIdsInfo })).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  const onSubmit: SubmitHandler<CardDetail> = async submitData => {
    try {
      setSubmitLoading(true)
      // cash pay method
      if (watchPaymentType !== 'creditCard') {
        return navigate({ pathname: '/checkout/review', search: `hotelId=${hotelId}&telephone=${telephone}` })
      }

      // credit card pay method
      let cardPayload = {}
      // payload if credit card data exist
      const reservationIds = checkoutReservations?.map(reservation => reservation.reservationId)

      let cardId = userCreditCard?.cardId
      if (!cardId) {
        const processResult = await dispatch(
          addCreditCard({
            cuicinUserId: userData.id,
            cardDetail: {
              cardNumber: submitData.cardNumber.replace(/ /g, ''),
              cardExpire: formatCardExpire({ cardExpireMonth: submitData.cardExpireMonth, cardExpireYear: submitData.cardExpireYear }),
              securityCode: submitData.securityCode,
            },
          }),
        ).unwrap()
        cardId = processResult.cardId
      }
      cardPayload = {
        cardId,
        paymentAmount: payments.totalAmount.toString(),
        reservationIds: reservationIds ?? [],
      }
      // pay with credit card
      const paymentsResult = await dispatch(creditCardPay({ ...cardPayload, hotelId })).unwrap()

      // if pay successful send payment info and redirect to review page
      if (paymentsResult.status === 'success') {
        try {
          const paymentPayload = {
            email: userData.email,
            hotelNameEn: hotelGuideData.facilityBasicInfo.hotelNameLatin,
            hotelNameJa: hotelGuideData.facilityBasicInfo.hotelName,
            hotelPhoneNumber: hotelGuideData.facilityBasicInfo.telephone,
            name: userData.name,
            paymentAmount: payments.totalAmount.toString(),
            paymentMethods: paymentsResult.cardBrand,
            receptionNumber: paymentsResult.orderId,
            receptionDate: dayjs().format(),
            usageDetailUrl:
              `${process.env.PUBLIC_URL}/mypage/usage-detail/${paymentsResult.orderId}` +
              `?hotelId=${hotelGuideData.hotelId}` +
              `&lang=${language}` +
              `&cuicinUserId=${userData.id}`,
            checkins: payments.reservations.map(reservation => {
              return {
                roomInfo: reservation.roomInfo ? reservation.roomInfo : [],
                useAmount: reservation.totalPrice,
                paymentAmount: reservation.totalPayment,
                internalDiscount: reservation.totalDiscount,
              }
            }),
          }

          await sendmailCheckoutPaymentInfo(paymentPayload)
        } catch (error) {
          errorHandler({ error })
        }

        await afterPayment(paymentsResult, submitData.receiptName)

        navigate({ pathname: '/checkout/review', search: `hotelId=${hotelId}&telephone=${telephone}` })
      }
    } catch (error) {
      errorHandler({ error })
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <Layout>
      <NavigateHeader title={t('Payment details')} />
      <Loading loading={loadPaymentLoading}>
        {payments?.reservations?.map((reservation, index) => {
          return <RoomBillDetail key={index} roomCss={index > 0 ? { marginTop: '1rem' } : {}} reservation={reservation} />
        })}
        <div css={spacerTopStyle}>
          <AmountBill totalAmount={payments.totalAmount} totalTaxPrice={payments.totalTaxPrice} />
        </div>

        <PaymentFormTemplate>
          <PaymentBlock useFormReturn={useFormReturn} paymentPlace="CHECKOUT" />
        </PaymentFormTemplate>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Loading loading={submitLoading}>
            <Button
              disabled={!isValid}
              buttonCss={[spacerTopStyle, horizontalSpacing]}
              text={t(watchPaymentType === 'creditCard' ? 'Calculate' : 'Next')}
              type="submit"
            />
          </Loading>
        </form>
      </Loading>
    </Layout>
  )
}
