import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { CheckInData, refineCheckInSchema, updateCheckInData } from '@/features/checkIn/check-in-slice'
import { HotelGuide } from '@/features/hotel/hotel-guide-slice'
import { AccountInfo, jpnSchema, njpSchema, putAccountInfo } from '@/features/user/account-slice'
import { getValueByLanguage } from '@/libs/get-value-by-language'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormLayout } from './form-layout'
import { CheckInEditField } from '../organisms/check-in-edit/check-in-edit-field'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { LanguageType } from '@/i18n'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { EditFormType } from '../organisms/check-in-edit'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import { RegisterAccountEditForm } from '../organisms/check-in-edit/register-account-edit-form'
import { CustomCheckInForm } from '../organisms/custom-check-in-form'
import { Button } from '../atoms/button'
import { formButtonStyle } from '@/styles/form'
import { css } from '@emotion/react'
import { setCustomCheckInData } from '@/features/checkIn/custom-check-in-field-slice'

export const CheckInEditTemplate: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const navigate = useNavigate()

  const hotelGuideData = useAppSelector<HotelGuide>(state => state.hotelGuide)
  const checkInData = useAppSelector<CheckInData>(state => state.checkIn.checkInData.fields)
  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const { customCheckin } = useAppSelector(state => state.customFields)
  const customCheckInFieldObj = customCheckin
    .filter(({ inputType }) => inputType !== 'none')
    .reduce((acc, currentField) => {
      const name = getValueByLanguage<string>({ object: currentField, lang: language as LanguageType, key: 'title' })
      acc[name] = currentField.value

      return acc
    }, {})

  const customCheckinRequiredSchema = z.object(
    customCheckin
      .filter(({ inputType, isRequired }) => inputType !== 'none' && isRequired)
      .reduce((acc, currentField) => {
        const name = getValueByLanguage<string>({ object: currentField, lang: language as LanguageType, key: 'title' })
        acc[name] = z.string().min(1)
        return acc
      }, {}),
  )
  const schema = z.union([
    jpnSchema.and(refineCheckInSchema).and(customCheckinRequiredSchema),
    njpSchema.and(refineCheckInSchema).and(customCheckinRequiredSchema),
  ])

  const useFormReturn = useForm<EditFormType>({
    mode: 'all',
    defaultValues: {
      ...accountInfo,
      checkInDate: checkInData.checkInDate,
      checkInTime: checkInData.checkInTime,
      guests: checkInData.guests,
      companions: checkInData.companions,
      knowContacts: checkInData.knowContacts,
      previousAccommodation: checkInData.previousAccommodation,
      previousPlace: checkInData.previousPlace,
      nextAccommodation: checkInData.nextAccommodation,
      nextPlace: checkInData.nextPlace,
      nationality: accountInfo.nationality || (language === 'ja' ? 'JPN' : 'NJP'),
      gender: accountInfo.gender || 'M',
      ...customCheckInFieldObj,
    },
    resolver: zodResolver(schema),
    shouldFocusError: true,
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormReturn

  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<EditFormType> = submitData => {
    dispatch(updateCheckInData({ ...checkInData, companions: submitData.companions }))

    let birthDate = accountInfo.birthDate
    if (submitData?.birthYear && submitData?.birthMonth && submitData?.birthDay) {
      birthDate = `${submitData.birthYear}-${submitData.birthMonth}-${submitData.birthDay}`
    }
    birthDate = dayjs(birthDate).format('YYYY-MM-DD')
    const nameKana = submitData.familyNameKana && submitData.givenNameKana ? `${submitData.familyNameKana} ${submitData.givenNameKana}` : ''

    dispatch(putAccountInfo({ ...accountInfo, ...submitData, birthDate, nameKana }))

    dispatch(setCustomCheckInData(customCheckin))

    navigate('/checkin-confirm')
  }

  useEffect(() => {
    if (!checkInData) {
      return navigate('/checkin')
    }

    if (hotelGuideData.hasCustomCheckInPlugins && isEmpty(customCheckin)) {
      return navigate('/custom-checkin')
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
      <FormLayout id="accommodation" layoutCss={spacerTopStyle}>
        <h3 css={titleStyle}>{t('Accommodation information')}</h3>
        <CheckInEditField hotelGuideData={hotelGuideData} useFormReturn={useFormReturn} />
      </FormLayout>

      <FormLayout id="representative" layoutCss={spacerTopStyle}>
        <h3 css={titleStyle}>{t('Representative information')}</h3>
        <RegisterAccountEditForm useFormReturn={useFormReturn} />
      </FormLayout>

      {!isEmpty(customCheckin) && (
        <FormLayout id="guidance" layoutCss={spacerTopStyle}>
          <h3 css={titleStyle}>{t('Guidance')}</h3>
          <CustomCheckInForm useFormReturn={useFormReturn} />
        </FormLayout>
      )}

      <Button disabled={!isValid} buttonCss={[spacerTopStyle, formButtonStyle]} text={t('Confirm')} type="submit" />
    </form>
  )
}

const titleStyle = css({
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '1rem',
})
