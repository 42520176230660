import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '@/components/template/form-layout'
import { AccountInfo, getAccountInfo } from '@/features/user/account-slice'
import { memberSchema, putMembershipInfo } from '@/features/user/membership-slice'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@/components/atoms/button'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { formButtonStyle } from '@/styles/form'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { useAppSelector } from '@/app/hooks'
import { errorHandler } from '@/libs/errors'
import { useLoginRedirect } from '@/hooks/use-login-redirect'
import { MembershipAccountForm } from '@/components/organisms/membership-account-form'
import { MembershipInfo } from '@/features/user/membership-slice'

export const MembershipAccountTemplate: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch<AppDispatch>()

  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const { ifNeedLoginRedirect } = useLoginRedirect()

  const useFormReturn = useForm<MembershipInfo>({
    mode: 'onBlur',
    resolver: zodResolver(memberSchema),
    defaultValues: {
      lastName: accountInfo.lastName,
      firstName: accountInfo.firstName,
      familyNameKana: accountInfo.familyNameKana,
      givenNameKana: accountInfo.givenNameKana,
      telephone: accountInfo.telephone,
      address: accountInfo.address,
      postalCode: accountInfo.postalCode,
    },
    shouldFocusError: true,
  })

  const { handleSubmit } = useFormReturn

  const onSubmit: SubmitHandler<MembershipInfo> = async submitData => {
    try {
      await dispatch(putMembershipInfo(submitData)).unwrap()
      await dispatch(getAccountInfo()).unwrap()
      if (ifNeedLoginRedirect()) {
        return
      }
    } catch (error) {
      errorHandler({ error })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
      <FormLayout>
        <MembershipAccountForm useFormReturn={useFormReturn} />
      </FormLayout>
      <Button buttonCss={[spacerTopStyle, formButtonStyle]} text={t('Next')} type="submit" />
    </form>
  )
}
