import React from 'react'
import { labelStyle, multipleSelectBoxStyle } from '@/styles/form'
import { useTranslation } from 'react-i18next'
import { RequiredMark } from '../atoms/required-mark'
import { spacerTopStyle } from '@/styles/common'
import { Input } from '../atoms/input'
import { UseFormReturn } from 'react-hook-form'
import { RHFSearchAddress } from '../molecules/rhf-search-address'
import { MembershipInfo } from '@/features/user/membership-slice'

interface MembershipAccountFormProps {
  useFormReturn: UseFormReturn<MembershipInfo>
}

export const MembershipAccountForm: React.FC<MembershipAccountFormProps> = ({ useFormReturn }) => {
  const { t } = useTranslation()

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormReturn

  const setAddressValue = (addressText: string) => {
    setValue('address', addressText)
  }

  return (
    <>
      <>
        <label css={labelStyle}>
          {t('Your name')}
          <RequiredMark />
        </label>
        <div css={[multipleSelectBoxStyle]}>
          <Input required {...register('lastName')} name="lastName" placeholder={t('Last name')} error={errors.lastName?.message} />

          <Input required {...register('firstName')} name="firstName" placeholder={t('First name')} error={errors.firstName?.message} />
        </div>
      </>

      <label css={[spacerTopStyle, labelStyle]}>
        {t('Furigana')}
        <RequiredMark />
      </label>
      <div css={[multipleSelectBoxStyle]}>
        <Input
          required
          {...register('familyNameKana', { required: true })}
          name="familyNameKana"
          placeholder={t('Surname')}
          error={errors.familyNameKana?.message}
        />

        <Input required {...register('givenNameKana')} name="givenNameKana" placeholder={t('May')} error={errors.givenNameKana?.message} />
      </div>

      <div css={spacerTopStyle}>
        <Input
          required
          {...register('telephone')}
          label={t('Phone number of your reservation')}
          name="telephone"
          placeholder="0123456789"
          error={errors.telephone?.message}
        />
      </div>

      <RHFSearchAddress control={control} name="postalCode" setAddressValue={setAddressValue} />

      <div css={spacerTopStyle}>
        <Input
          required
          {...register('address')}
          label={t('subsequent addresses')}
          name="address"
          placeholder={t('Street, City, State, Country')}
          error={errors.address?.message}
        />
      </div>
    </>
  )
}
