import React, { useEffect } from 'react'
import { Layout } from '../../template/layout'
import { NavigateHeader } from '../../atoms/navigate-header'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'
import { useAppSelector } from '@/app/hooks'
import { CheckInEditTemplate } from '@/components/template/check-in-edit-template'
import { isEmpty } from 'lodash'

export const CheckInEdit: React.FC = () => {
  const { t } = useTranslation()

  const { smoothScroll } = useSmoothScroll()
  const [searchParams] = useSearchParams()

  const accountInfo = useAppSelector(state => state.accountInfo.user)

  useEffect(() => {
    const hash = searchParams.get('hash')
    if (hash) {
      smoothScroll(hash)
    }
  }, [searchParams])

  return (
    <Layout>
      <NavigateHeader title={t('Edit registration information')} />
      {!isEmpty(accountInfo) && <CheckInEditTemplate />}
    </Layout>
  )
}
