import React, { useEffect } from 'react'
import { useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { RequestItem } from '@/features/request/request-cart-slice'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { RequestConfirmationForm } from '@/components/organisms/request/request-confirmation-form'

export const RequestConfirmation: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { assetId } = useParams<{ assetId: string }>()
  const requestCart = useAppSelector(state => state.request.requestCart.cart)
  const requestCarts: RequestItem[] = assetId ? requestCart[assetId] : []
  const assets = useAppSelector(state => state.assets.assets)

  useEffect(() => {
    if (isEmpty(requestCarts)) {
      return navigate(`/request/${assetId}`)
    }
  }, [requestCarts])

  useEffect(() => {
    if (!assets.find((asset) => asset.id !== assetId)) {
      return navigate('/')
    }
  }, [assets])

  return (
    <Layout>
      <NavigateHeader title={t('Payment details')} />
      {!isEmpty(requestCarts) && assetId && <RequestConfirmationForm requestCarts={requestCarts} assetId={assetId} />}
    </Layout>
  )
}
