import React from 'react'
import { css } from '@emotion/react'
import { SelectBox } from '../../atoms/select-box'
import { useAppSelector } from '@/app/hooks'
import { CheckInData, setCheckInForm } from '@/features/checkIn/check-in-slice'
import { getHoursTimeRange } from '@/utils/time'
import { HotelGuide } from '@/features/hotel/hotel-guide-slice'
import { spacerTopStyle } from '@/styles/common'
import { useTranslation } from 'react-i18next'
import { CheckBox } from '../../atoms/check-box'
import { Input } from '../../atoms/input'
import { CloseButton } from '../../atoms/close-button'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { UseFormReturn, Controller, useFieldArray } from 'react-hook-form'
import { guestsOptions } from '@/utils/form/options'
import { RequiredMark } from '../../atoms/required-mark'
import { labelStyle } from '@/styles/form'
import { DatePickerButton } from '../../atoms/date-calendar'
import { EditFormType } from '.'
import dayjs from 'dayjs'

interface CheckInEditFieldProps {
  hotelGuideData: HotelGuide
  useFormReturn: UseFormReturn<EditFormType>
}

export const CheckInEditField: React.FC<CheckInEditFieldProps> = ({ hotelGuideData, useFormReturn }) => {
  const { t } = useTranslation()

  const checkInData = useAppSelector<CheckInData>(state => state.checkIn.checkInData.fields)
  const dispatch = useDispatch<AppDispatch>()

  const {
    watch,
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormReturn

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companions',
  })

  const setCheckInData = (checkInData: CheckInData) => {
    dispatch(setCheckInForm(checkInData))
  }

  const watchGuests = watch('guests')
  const watchCompanions = watch('companions')
  const watchNextAccommodation = watch('nextAccommodation')
  const watchPreviousAccommodation = watch('previousAccommodation')
  const watchKnowContracts = watch('knowContacts')

  return (
    <>
      {!hotelGuideData.hasReservationPlugins && (
        <div css={spacerTopStyle}>
          <DatePickerButton
            maxDate={dayjs().add(14, 'day').toDate()}
            required
            {...register('checkInDate')}
            onSetValue={date => {
              setValue('checkInDate', date)
              setCheckInData({ ...checkInData, checkInDate: date })
            }}
            value={checkInData.checkInDate}
            errorMessage={errors.checkInDate?.message}
            label={t('Arrival Date')}
          />
        </div>
      )}

      <Controller
        control={control}
        name="checkInTime"
        render={({ field }) => (
          <SelectBox
            {...register('checkInTime')}
            required
            label={t('Check-in Time')}
            id="checkInTime"
            name="checkInTime"
            options={getHoursTimeRange({
              beginTime: hotelGuideData.facilityBasicInfo.checkinBeginTime,
              endTime: hotelGuideData.facilityBasicInfo.checkinEndTime,
            }).map(value => ({
              label: value,
              value,
            }))}
            itemCss={spacerTopStyle}
            onChange={e => {
              const value = e.target.value

              field.onChange(value)
              setCheckInData({ ...checkInData, checkInTime: value })
            }}
            error={errors.checkInTime?.message}
          />
        )}
      />

      <Controller
        name="guests"
        control={control}
        render={({ field }) => (
          <SelectBox
            required
            {...register('guests')}
            arrowDefaultSelect={false}
            label={t('Number of guests')}
            id="guests"
            name="guests"
            options={guestsOptions}
            itemCss={spacerTopStyle}
            onChange={e => {
              const value = Number(e.target.value)

              const renderFields = Array.from(Array(value - 1)).map(() => ({
                lastName: '',
                firstName: '',
              }))

              remove()
              append(renderFields)
              field.onChange(value)
              setCheckInData({ ...checkInData, guests: value })
            }}
          />
        )}
      />

      {watchGuests > 1 && (
        <div css={spacerTopStyle}>
          <CheckBox
            {...register('knowContacts')}
            customLabelStyle={css({ fontSize: '12px' })}
            label={t('I know everyone’s contacts')}
            checked={Boolean(watchKnowContracts)}
          />
          {!watchKnowContracts && (
            <>
              <div css={spacerTopStyle}>
                <label css={labelStyle}>
                  {t('Companions')}
                  <RequiredMark />
                </label>
                {fields.map((field, index) => (
                  <div css={[multipleSelectBoxStyle, { alignItems: 'flex-start' }]} key={field.id}>
                    <Input
                      {...register(`companions.${index}.lastName`)}
                      itemCss={css({ flex: 1 })}
                      name={`companions.${index}.lastName`}
                      placeholder={t('Last name')}
                      error={errors?.companions?.[index]?.lastName?.message}
                    />
                    <Input
                      {...register(`companions.${index}.firstName`)}
                      itemCss={css({ flex: 1 })}
                      name={`companions.${index}.firstName`}
                      placeholder={t('First name')}
                      error={errors?.companions?.[index]?.firstName?.message}
                    />
                    <CloseButton
                      onClick={() => {
                        remove(index)
                        setValue('guests', watchCompanions.length)
                        setCheckInData({ ...checkInData, guests: watchCompanions.length })
                      }}
                      customStyle={css({ marginTop: '12px' })}
                    />
                  </div>
                ))}
              </div>
              <p
                css={addTextStyle}
                onClick={() => {
                  append({ firstName: '', lastName: '' })

                  setValue('guests', watchCompanions.length + 2)
                  setCheckInData({ ...checkInData, guests: watchCompanions.length + 2 })
                }}
              >
                <span css={plusIconStyle}></span>
                {t('Add entry field')}
              </p>
            </>
          )}
        </div>
      )}

      <div css={spacerTopStyle}>
        <Controller
          control={control}
          name="previousAccommodation"
          render={({ field }) => (
            <SelectBox
              {...register('previousAccommodation')}
              label={t('Previous place of stay')}
              name="previousAccommodation"
              options={[
                { value: 'Home', label: t('Home') },
                { value: 'Other', label: t('Other') },
              ]}
              onChange={e => {
                const value = e.target.value

                field.onChange(value)
                setCheckInData({ ...checkInData, previousAccommodation: value })
              }}
            />
          )}
        />

        {watchPreviousAccommodation === 'Other' && (
          <Controller
            control={control}
            name="previousPlace"
            render={({ field }) => (
              <Input
                {...register('previousPlace')}
                itemCss={spacerTopStyle}
                name="previousPlace"
                placeholder="Tokyo/Hotel name"
                onChange={e => {
                  const value = e.target.value

                  field.onChange(value)
                  setCheckInData({ ...checkInData, previousPlace: value })
                }}
              />
            )}
          />
        )}
      </div>

      <div css={spacerTopStyle}>
        <Controller
          control={control}
          name="nextAccommodation"
          render={({ field }) => (
            <SelectBox
              {...register('nextAccommodation')}
              label={t('Next destination')}
              name="nextAccommodation"
              options={[
                { value: 'Home', label: t('Home') },
                { value: 'Other', label: t('Other') },
              ]}
              onChange={e => {
                const value = e.target.value

                field.onChange(value)
                setCheckInData({ ...checkInData, nextAccommodation: value })
              }}
            />
          )}
        />

        {watchNextAccommodation === 'Other' && (
          <Controller
            control={control}
            name="nextPlace"
            render={({ field }) => (
              <Input
                {...register('nextPlace')}
                itemCss={spacerTopStyle}
                name="nextPlace"
                placeholder="Tokyo/Hotel name"
                onChange={e => {
                  const value = e.target.value

                  field.onChange(value)
                  setCheckInData({ ...checkInData, nextPlace: value })
                }}
              />
            )}
          />
        )}
      </div>
    </>
  )
}

const multipleSelectBoxStyle = css({
  display: 'flex',
  gap: '16px',
  marginTop: '1.5rem',
  alignItems: 'center',
  '&:first-of-type': {
    marginTop: 0,
  },
})

const plusIconStyle = css({
  display: 'inline-block',
  verticalAlign: 'middle',
  color: '#333',
  lineHeight: 1,
  width: '12px',
  height: '2px',
  background: '#A59785',
  borderRadius: '0.1em',
  position: 'relative',
  '&:before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'inherit',
    transform: 'rotate(90deg)',
  },
})

const addTextStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',
  color: '#A59785',
  fontSize: '12px',
  marginTop: '1rem',
})
