import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { Layout } from '@/components/template/layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import dayjs from 'dayjs'
import { globalHotelId } from '@/hooks/use-hotel-id'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BLOCK, CUSTOM_BORDER } from '@/components/template/custom-design-style'
import { displayYen } from '@/libs/text'
import { v4 as uuid } from 'uuid'
import { Button } from '@/components/atoms/button'
import { CarouselItemWrapper } from '@/components/atoms/carousel-item-wrapper'
import { useTranslation } from 'react-i18next'
import { DatePickerButton } from '@/components/atoms/date-calendar'
import {
  ReservationForm,
  SpaceDetail as SpaceDetailType,
  getSpaceAvailableTime,
  getStartAvailabilityTimes,
  storeFormValues,
  timeToMinutes,
} from '@/features/space/spaces-slice'
import { SelectBox } from '@/components/atoms/select-box'
import { SpacePriceLabel } from '@/components/organisms/space/space-price-label'
import { ReadMoreText } from '@/components/molecules/read-mone-text'
import { TextArea } from '@/components/atoms/text-area'
import { ReserveTimeBar } from '@/components/organisms/space/reserve-time-bar'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ReserveTimeBox } from '@/components/organisms/space/reserve-time-box'
import { ReservationConfirmModal } from '@/components/organisms/space/reservation-confirm-modal'
import { minScreen } from '@/styles/media-query'
import { useBreakPoint } from '@/hooks/use-break-point'
import { ImagePopup } from '@/components/molecules/image-popup'

export const SpaceDetail: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const { isPc } = useBreakPoint()

  const { assetId, spaceId } = useParams<{ assetId: string; spaceId: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pickByLanguage = usePickByLanguage()
  const { hasReservationPlugins } = useAppSelector(state => state.hotelGuide)
  const spaceDetails = useAppSelector(state => state.spaces?.spaceDetails)
  const formValues = useAppSelector(state => state.spaces?.formValues)
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ReservationForm>({
    shouldUnregister: false,
    defaultValues: {
      spaceId,
      useDay: formValues?.useDay || dayjs().format('YYYY-MM-DD'),
      quantity: 1,
      usageFromHour: formValues?.usageFromHour || '00',
      usageFromMinute: formValues?.usageFromMinute || '00',
      usageToHour: '00',
      usageToMinute: '00',
      answer: '',
      pax: 1,
    },
  })
  const watchValues = {
    quantity: useWatch({ control, name: 'quantity' }),
    useDay: useWatch({ control, name: 'useDay' }),
    usageFromHour: useWatch({ control, name: 'usageFromHour' }),
    usageFromMinute: useWatch({ control, name: 'usageFromMinute' }),
    usageToHour: useWatch({ control, name: 'usageToHour' }),
    usageToMinute: useWatch({ control, name: 'usageToMinute' }),
  }

  const spaceInfo = useMemo(() => spaceDetails.find(detail => detail.id === spaceId), [spaceDetails])
  const paxOptions = useMemo(() => [...Array(20)].map((v, i) => ({ value: `${i + 1}`, label: `${i + 1}${t('Guests')}` })), [])
  const quantityOptions = useMemo(
    () => [...Array(spaceInfo?.stock)].map((v, i) => ({ value: `${i + 1}`, label: `${i + 1}${pickByLanguage(spaceInfo?.stockUnit)}` })),
    [spaceInfo],
  )

  const selectedMinutes = useMemo(() => {
    if (!watchValues.usageFromHour || !watchValues.usageFromMinute || !watchValues.usageToHour || !watchValues.usageToMinute) {
      return 0
    }
    const usageFromMinute = Number(watchValues.usageFromHour) * 60 + Number(watchValues.usageFromMinute)
    const usageToMinute = Number(watchValues.usageToHour === '00' ? '24' : watchValues.usageToHour) * 60 + Number(watchValues.usageToMinute)
    return usageToMinute - usageFromMinute
  }, [watchValues.usageFromHour, watchValues.usageFromMinute, watchValues.usageToHour, watchValues.usageToMinute])

  const fetchSpaceList = () => {
    if (!assetId) {
      return
    }
    dispatch(
      getSpaceAvailableTime({
        hotelId: globalHotelId(),
        guestAppAssetId: assetId,
        useDay: dayjs(watchValues.useDay),
      }),
    )
  }

  const onSubmit = (value: ReservationForm) => {
    dispatch(storeFormValues({ ...value, totalAmount: calcTotalPrice }))
    if (hasReservationPlugins) {
      setModalOpen(true)
    } else {
      navigate(`/space/${assetId}/application-confirm`)
    }
  }

  useEffect(() => {
    fetchSpaceList()
  }, [watchValues.useDay])

  const calcTotalPrice = useMemo((): string | undefined => {
    if (!spaceInfo?.spacePrice?.chargePrice.unitPrice) {
      if (spaceInfo?.spacePrice?.chargePrice.maxPrice) {
        return `${spaceInfo.spacePrice.chargePrice.maxPrice}`
      }

      return undefined
    }
    const { unitPrice, maxPrice } = spaceInfo.spacePrice.chargePrice
    const chargePrice = Math.floor((selectedMinutes / 60) * unitPrice)
    const totalPricePerQuantity = Math.min(chargePrice, maxPrice || chargePrice)
    return `${totalPricePerQuantity * watchValues.quantity}`
  }, [selectedMinutes, watchValues.quantity])

  const existsStartTime = (space: SpaceDetailType, quantity: number): boolean => {
    const minUsageMinute = timeToMinutes(space.spacePrice?.chargePrice.usageTime.start)
    return getStartAvailabilityTimes(space.availabilityTimes, quantity, minUsageMinute).length > 0
  }

  return (
    <Layout>
      {spaceInfo && (
        <>
          {isPc ? (
            <div>
              <img src={spaceInfo.images[0]} style={{ width: '100%', height: 'auto', maxHeight: '355px', objectFit: 'cover' }} />
            </div>
          ) : (
            <CarouselItemWrapper style={{ width: '100%', gap: '0' }} isShowSnapDisplay>
              {spaceInfo.images.map(imagePath => (
                <div key={uuid()} className="embla__slide">
                  <img src={imagePath} style={{ width: '100vw' }} />
                </div>
              ))}
            </CarouselItemWrapper>
          )}
          <main css={mainStyle}>
            <div css={spaceInfoBlockStyle} className={CUSTOM_BLOCK}>
              <div className="title-text">{pickByLanguage(spaceInfo.title)}</div>
              <SpacePriceLabel space={spaceInfo} />
              <div className="description-text">
                {isPc ? (
                  pickByLanguage(spaceInfo.description)
                ) : (
                  <ReadMoreText defaultLine={4}>{pickByLanguage(spaceInfo.description)}</ReadMoreText>
                )}
              </div>
              {isPc && (
                <div css={photoAreaStyle}>
                  <p className="photo-title">{t('photo')}</p>

                  <div className="photo">
                    <ImagePopup imagePaths={spaceInfo.images} />
                  </div>
                </div>
              )}
            </div>

            <div>
              <div css={selectTimeBlockStyle} className={CUSTOM_BLOCK}>
                <div className="sub-title">{t('Date of use')}</div>
                <div className="usage-day-area">
                  <Controller
                    control={control}
                    name="useDay"
                    render={({ field }) => <DatePickerButton {...field} onSetValue={date => setValue(field.name, date)} />}
                  />
                  <div className="quantity-select-box">
                    <SelectBox {...register('quantity')} options={quantityOptions} arrowDefaultSelect={false} />
                  </div>
                </div>
                <hr style={{ margin: '24px 0 0 0', borderWidth: 0, borderTopWidth: 1 }} className={CUSTOM_BORDER}></hr>

                {existsStartTime(spaceInfo, watchValues.quantity) ? (
                  <>
                    <div className="usage-time-area">
                      <ReserveTimeBox space={spaceInfo} control={control} setValue={setValue} watchValues={watchValues} />
                    </div>
                    <ReserveTimeBar space={spaceInfo} watchValues={watchValues} />
                  </>
                ) : (
                  <div className="usage-time-area" style={{ fontSize: 14 }}>
                    {t('No available')}
                  </div>
                )}

                {isPc && existsStartTime(spaceInfo, watchValues.quantity) && (
                  <>
                    <hr style={{ margin: '24px -25px 0 -16px', borderWidth: 0, borderTopWidth: 1 }} className={CUSTOM_BORDER}></hr>
                    <div css={priceBlockStyle} className={CUSTOM_BLOCK}>
                      <div className="price-label">{t('Total amount')}</div>
                      <div className="total-price-text">{calcTotalPrice ? displayYen(calcTotalPrice) : '-'}</div>
                    </div>
                  </>
                )}
              </div>

              {existsStartTime(spaceInfo, watchValues.quantity) && (
                <>
                  {!isPc && (
                    <div css={priceBlockStyle} className={CUSTOM_BLOCK}>
                      <div className="price-label">{t('Total amount')}</div>
                      <div className="total-price-text">{calcTotalPrice ? displayYen(calcTotalPrice) : '-'}</div>
                    </div>
                  )}
                  <div css={questionBlock} className={CUSTOM_BLOCK}>
                    <div>
                      <div className="sub-title">{t('Requests from Facilities')}</div>
                      <div className="question-text">{pickByLanguage(spaceInfo?.question)}</div>
                      <div className="answer-text-area">
                        <TextArea {...register('answer')} label="" rows={3} />
                      </div>
                    </div>
                    <div>
                      <div className="sub-title">{t('Number of users')}</div>
                      <div className="pax-select-box">
                        <SelectBox {...register('pax')} options={paxOptions} arrowDefaultSelect={false} />
                      </div>
                    </div>
                    {isPc && (
                      <>
                        <hr style={{ margin: '40px -25px 22px -16px', borderWidth: 0, borderTopWidth: 1 }} className={CUSTOM_BORDER}></hr>
                        <Button disabled={!isValid} text={t('Reserve')} onClick={handleSubmit(onSubmit)} />
                      </>
                    )}
                  </div>
                  {!isPc && (
                    <div css={footerBlockStyle}>
                      <Button disabled={!isValid} text={t('Reserve')} onClick={handleSubmit(onSubmit)} />
                    </div>
                  )}

                  <ReservationConfirmModal spaceInfo={spaceInfo} modalOpen={modalOpen} />
                </>
              )}
            </div>
          </main>
        </>
      )}
    </Layout>
  )
}

const mainStyle = css({
  [minScreen()]: {
    display: 'flex',
    maxWidth: 1000,
    position: 'relative',
    margin: '-102px auto 0',
    gap: 16,
    zIndex: 1,
  },
})
const spaceInfoBlockStyle = css({
  padding: '25px 16px 25px 16px',
  [minScreen()]: {
    flex: '0 0 656px',
    padding: '32px',
    borderRadius: 10,
    boxShadow: '0px 0px 10px #00000029',
  },
  '.title-text': {
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 'bold',
    [minScreen()]: {
      fontSize: 32,
    },
  },
  '.description-text': {
    paddingTop: 24,
    fontSize: 14,
    letterSpacing: 0.7,
    lineHeight: '22px',
    whiteSpace: 'pre-wrap',
  },
})
const selectTimeBlockStyle = css({
  marginTop: 8,
  padding: '32px 25px 32px 16px',
  [minScreen()]: {
    marginTop: 0,
    flex: '0 0 328px',
    borderRadius: 10,
    boxShadow: '0px 0px 10px #00000029',
  },
  '.sub-title': {
    fontSize: 14,
    letterSpacing: 1.4,
    fontWeight: 'bold',
    paddingBottom: 4,
    [minScreen()]: {
      fontSize: 16,
    },
  },
  '.usage-day-area': {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    marginTop: 8,
    '.quantity-select-box': {
      width: 140,
    },
    '.select-wrapper': { marginTop: 0 },
  },
  '.usage-time-area': {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    paddingTop: 24,
    '> div': { width: 72 },
  },
})
const priceBlockStyle = css({
  marginTop: 8,
  padding: '24px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [minScreen()]: {
    marginTop: 1,
    padding: '24px 0 0',
  },
  '.price-label': {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1.4,
    [minScreen()]: {
      fontSize: 16,
    },
  },
  '.total-price-text': {
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 0.8,
  },
})
const questionBlock = css({
  marginTop: 8,
  padding: '32px 25px 32px 16px',
  [minScreen()]: {
    marginTop: 16,
    borderRadius: 10,
    boxShadow: '0px 0px 10px #00000029',
  },
  '.sub-title': {
    fontSize: 14,
    letterSpacing: 1.4,
    fontWeight: 'bold',
    paddingBottom: 2,
    [minScreen()]: {
      fontSize: 16,
    },
  },
  '.question-text': {
    whiteSpace: 'pre-wrap',
    paddingTop: 8,
    paddingBottom: 16,
  },
  '.question-text, textarea': {
    fontSize: 14,
    letterSpacing: 0.7,
    lineHeight: '22px',
  },
  '.answer-text-area': {
    paddingBottom: 24,
  },
  '.pax-select-box': {
    width: 120,
  },
})

const photoAreaStyle = css({
  '.photo-title': {
    marginTop: 32,
    marginBottom: 16,
    fontSize: 21,
    fontWeight: 'bold',
  },
  '.photo': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 11,
    div: {
      width: 190,
      height: 108,
      img: {
        width: '100%',
        height: '100%',
        borderRadius: 6,
      },
    },
  },
})

const footerBlockStyle = css({
  padding: '32px 22px 0 22px',
  [minScreen()]: {
    marginTop: 1,
    paddingBottom: 32,
  },
})
