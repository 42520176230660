import { pcScreenStyle } from '@/styles/common'
import React from 'react'

interface ContentWrapperProps {
  children: React.ReactNode
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  return <div css={pcScreenStyle}>{children}</div>
}
