import React, { useEffect } from 'react'
import { FormLayout } from '@/components/template/form-layout'
import { Layout } from '@/components/template/layout'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { useTranslation } from 'react-i18next'
import { CustomCheckInForm } from '@/components/organisms/custom-check-in-form'
import { useAppSelector } from '@/app/hooks'
import { Button } from '@/components/atoms/button'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { formButtonStyle } from '@/styles/form'
import { AppDispatch } from '@/app/store'
import { useDispatch } from 'react-redux'
import { fetchCustomFields, setCustomCheckInData } from '@/features/checkIn/custom-check-in-field-slice'
import { getCheckInPayments } from '@/features/checkIn/check-in-payment-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { errorHandler } from '@/libs/errors'
import { isEmpty } from 'lodash'
import { getValueByLanguage } from '@/libs/get-value-by-language'
import { LanguageType } from '@/i18n'

export const CustomCheckIn: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const searchParams = useQuerySearchParams<{ hotelId: string }>()

  const customCheckInFields = useAppSelector(state => state.customFields.customCheckin)
  const customCheckInFieldObj = customCheckInFields
    .filter(({ inputType }) => inputType !== 'none')
    .reduce((acc, currentField) => {
      const name = getValueByLanguage<string>({ object: currentField, lang: language as LanguageType, key: 'title' })
      acc[name] = currentField.value

      return acc
    }, {})

  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const { hasPaymentPlugins } = useAppSelector(state => state.hotelGuide)
  const checkInData = useAppSelector(state => state.checkIn.checkInData.fields)

  const useFormReturn = useForm({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: customCheckInFieldObj,
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormReturn

  const onSubmit = async () => {
    dispatch(setCustomCheckInData(customCheckInFields))
    const paymentMethodValues: string[] = []
    const selfCheckinPaymentSetting = paymentSetting?.payment_time?.find(item => item.value === 'CHECKIN')

    if (selfCheckinPaymentSetting && selfCheckinPaymentSetting.is_enabled) {
      for (let index = 0; index < selfCheckinPaymentSetting.payment_method.length; index++) {
        const paymentMethod = selfCheckinPaymentSetting.payment_method[index]
        if (paymentMethod.is_enabled) {
          if (paymentMethod.value === 'DIRECT') {
            paymentMethodValues.push('cash')
          } else if (paymentMethod.value === 'CREDIT_CARD') {
            paymentMethodValues.push('creditCard')
          }
        }
      }
    }

    let payments
    if (checkInData?.reservationIds) {
      payments = await dispatch(
        getCheckInPayments({
          reservationIds: checkInData.reservationIds,
          hotelId: searchParams.hotelId,
        }),
      ).unwrap()
    }

    if (hasPaymentPlugins && payments?.totalAmount > 0 && paymentMethodValues.length) {
      navigate('/checkin-payment')
    } else {
      navigate('/checkin-confirm')
    }
  }

  const loadCustomCheckinField = async () => {
    try {
      await dispatch(fetchCustomFields({ hotelId: searchParams.hotelId })).unwrap()
    } catch (error) {
      console.error(error)
      errorHandler({ error })
    }
  }

  useEffect(() => {
    if (isEmpty(customCheckInFields)) {
      loadCustomCheckinField()
    }
  }, [])

  return (
    <Layout>
      <NavigateHeader title={t('Guidance')} />
      <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
        <FormLayout>
          <CustomCheckInForm useFormReturn={useFormReturn} />
        </FormLayout>

        <Button disabled={!isValid} buttonCss={[formButtonStyle, spacerTopStyle]} text={t('Next')} type="submit" />
      </form>
    </Layout>
  )
}
