import { useAppDispatch } from '@/app/hooks'
import { Button } from '@/components/atoms/button'
import { Input } from '@/components/atoms/input'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Loading } from '@/components/organisms/loading'
import { ResetPasswordModal } from '@/components/organisms/modal/reset-password-modal'
import { FormLayout } from '@/components/template/form-layout'
import { Layout } from '@/components/template/layout'
import { checkEmail, forgotPassword } from '@/features/auth/auth-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { errorHandler } from '@/libs/errors'
import { formButtonStyle } from '@/styles/form'
import { css } from '@emotion/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const schema = z.object({
  email: z.string().email(),
})

export const ForgotPassword: React.FC = () => {
  const [mailStatus, setMailStatus] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const searchParams = useQuerySearchParams<{ email: string }>()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const { register, handleSubmit, watch } = useForm<{ email: string }>({
    mode: 'onSubmit',
    defaultValues: {
      email: searchParams.email ?? undefined,
    },
    resolver: zodResolver(schema),
  })
  const watchEmail = watch('email')

  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<{ email: string }> = async submitData => {
    try {
      setLoading(true)
      await dispatch(
        forgotPassword({
          email: submitData.email,
          hotelId: hotelId,
          language,
        }),
      ).unwrap()

      const mailStatus = await dispatch(checkEmail(submitData.email)).unwrap()
      setMailStatus(mailStatus)

      setIsModalOpen(true)
    } catch (error) {
      errorHandler({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout>
      <NavigateHeader title={t('Reset your password')} />

      <Loading loading={loading}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLayout>
            <Input {...register('email')} required label={t('email address')} placeholder={t('email address')} name="email" type="email" />

            <p css={registerTextStyle}>{t('Please enter your e-mail address that you registered.')}</p>
          </FormLayout>

          <div style={{ marginTop: '2rem' }}>
            <Button disabled={isEmpty(watchEmail)} buttonCss={formButtonStyle} text={t('Next')} type="submit" />
          </div>
        </form>
      </Loading>
      <ResetPasswordModal email={watchEmail} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} mailStatus={mailStatus} />
    </Layout>
  )
}

const registerTextStyle = css({
  fontSize: '12px',
  textAlign: 'center',
  paddingTop: '0.75rem',
})
