import React, { useEffect } from 'react'
import { useAppSelector } from '@/app/hooks'
import { AppDispatch } from '@/app/store'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { CheckInPaymentForm } from '@/components/organisms/check-in-payment-form'
import { Layout } from '@/components/template/layout'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getCheckInPayments } from '@/features/checkIn/check-in-payment-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { AmountBill, RoomBillDetail } from '@/components/organisms/room-bill-detail'
import { useNavigate } from 'react-router-dom'
import { Loading } from '@/components/organisms/loading'
import { getSessionCheckInData } from '@/features/checkIn/check-in-slice'
import { PaymentFormTemplate } from '@/components/template/payment-form-template'

export const CheckInPayment: React.FC = () => {
  const searchParams = useQuerySearchParams<{ hotelId: string }>()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()

  const { payments, loading } = useAppSelector(state => state.checkIn.checkInPayments)

  const loadPayments = async () => {
    const checkInData = getSessionCheckInData()
    try {
      if (!checkInData?.reservationIds) {
        return navigate('/checkin-reservation-confirm')
      }

      const loadPayments = await dispatch(
        getCheckInPayments({ hotelId: searchParams.hotelId, reservationIds: checkInData.reservationIds }),
      ).unwrap()

      if (loadPayments.totalAmount <= 0) {
        return navigate({ pathname: '/checkin-confirm' })
      }
    } catch (error) {
      navigate({ pathname: '/checkin' })
    }
  }

  useEffect(() => {
    loadPayments()
  }, [])

  return (
    <Layout>
      <NavigateHeader title={t('Payment details')} />

      <Loading loading={loading}>
        {payments && (
          <div css={pcScreenStyle}>
            {payments?.reservations?.map((reservation, index) => {
              return <RoomBillDetail key={index} roomCss={index > 0 ? { marginTop: '1rem' } : {}} reservation={reservation} />
            })}

            {payments.totalAmount > 0 && (
              <div css={spacerTopStyle}>
                <AmountBill totalAmount={payments.totalAmount} totalTaxPrice={payments.totalTaxPrice} />
              </div>
            )}

            <PaymentFormTemplate>
              <CheckInPaymentForm />
            </PaymentFormTemplate>
          </div>
        )}
      </Loading>
    </Layout>
  )
}
