import React from 'react'
import { explanatoryNoteStyle, labelStyle, multipleSelectBoxStyle } from '@/styles/form'
import { useTranslation } from 'react-i18next'
import { RequiredMark } from '../atoms/required-mark'
import { RadioButton } from '../atoms/radio-button'
import { spacerTopStyle } from '@/styles/common'
import { Input } from '../atoms/input'
import { css } from '@emotion/react'
import { SelectBox } from '../atoms/select-box'
import { isEmployeeByOccupation, isOtherByOccupation, occupationOptions } from '@/utils/form/occupation'
import { AccountInfo } from '@/features/user/account-slice'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ErrorMessage } from '../atoms/error-message'
import moment from 'moment'
import { RHFSearchAddress } from '../molecules/rhf-search-address'

interface RegisterAccountFormProps {
  useFormReturn: UseFormReturn<AccountInfo>
}

export const RegisterAccountForm: React.FC<RegisterAccountFormProps> = ({ useFormReturn }) => {
  const { t } = useTranslation()

  const {
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = useFormReturn

  const watchNationality = watch('nationality')
  const watchGender = watch('gender')
  const watchOccupation = watch('occupation')

  const dateValidation = () => {
    const year = getValues('birthYear')
    const month = getValues('birthMonth')
    const day = getValues('birthDay')

    if (!year || !month || !day) {
      return clearErrors('birthDate')
    }

    const birthDate = `${year?.padStart(4, '0')}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`

    const isValidBirthDate = moment(birthDate, 'YYYY-MM-DD', true).isValid()

    if (!isValidBirthDate) {
      setError('birthDate', { message: t('Invalid date') }, { shouldFocus: true })
    } else {
      clearErrors('birthDate')
    }
  }

  const renderBirthDateError = () => {
    const errorMessage = errors.birthYear?.message || errors.birthMonth?.message || errors.birthDay?.message || errors.birthDate?.message

    if (errorMessage) {
      return <ErrorMessage text={errorMessage} />
    }
  }

  const setAddressValue = (addressText: string) => {
    setValue('address', addressText)
  }

  return (
    <>
      <label css={labelStyle}>
        {t('Nationality')}
        <RequiredMark />
      </label>
      <div css={[multipleRadioButtonStyle, multipleSelectBoxStyle]}>
        <RadioButton
          {...register('nationality')}
          label={t('Japanese')}
          checked={watchNationality === 'JPN'}
          name="nationality"
          value="JPN"
        />

        <RadioButton
          {...register('nationality')}
          label={t('non-Japanese')}
          checked={watchNationality === 'NJP'}
          name="nationality"
          value="NJP"
        />
      </div>

      <>
        <label css={[spacerTopStyle, labelStyle]}>
          {t('Your name')}
          <RequiredMark />
        </label>
        <div css={[multipleSelectBoxStyle]}>
          <Input required {...register('lastName')} name="lastName" placeholder={t('Last name')} error={errors.lastName?.message} />

          <Input required {...register('firstName')} name="firstName" placeholder={t('First name')} error={errors.firstName?.message} />
        </div>
      </>

      {watchNationality === 'JPN' && (
        <>
          <label css={[spacerTopStyle, labelStyle]}>
            {t('Furigana')}
            <RequiredMark />
          </label>
          <div css={[multipleSelectBoxStyle]}>
            <Input
              required
              {...register('familyNameKana', { required: true })}
              name="familyNameKana"
              placeholder={t('Surname')}
              error={errors.familyNameKana?.message}
            />

            <Input
              required
              {...register('givenNameKana')}
              name="givenNameKana"
              placeholder={t('May')}
              error={errors.givenNameKana?.message}
            />
          </div>
        </>
      )}

      <div css={spacerTopStyle}>
        <label css={labelStyle}>
          {t('Date of birth')}
          <RequiredMark />
        </label>

        <div css={[multipleSelectBoxStyle, birthDateWidth]}>
          <Controller
            control={control}
            name="birthYear"
            render={({ field }) => (
              <Input
                {...register('birthYear')}
                placeholder="1985"
                type="number"
                name="birthYear"
                onChange={e => {
                  field.onChange(e.target.value)
                }}
                onBlur={() => {
                  field.onBlur()
                  dateValidation()
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="birthMonth"
            render={({ field }) => (
              <Input
                {...register('birthMonth')}
                placeholder="01"
                type="number"
                name="birthMonth"
                onChange={e => {
                  field.onChange(e.target.value)
                }}
                onBlur={() => {
                  field.onBlur()
                  dateValidation()
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="birthDay"
            render={({ field }) => (
              <Input
                {...register('birthDay')}
                placeholder="01"
                type="number"
                name="birthDay"
                onChange={e => {
                  field.onChange(e.target.value)
                }}
                onBlur={() => {
                  field.onBlur()
                  dateValidation()
                }}
              />
            )}
          />
        </div>

        {renderBirthDateError()}
      </div>

      <div css={spacerTopStyle}>
        <label css={labelStyle}>
          {t('Gender')}
          <RequiredMark />
        </label>

        <div css={[multipleRadioButtonStyle, multipleSelectBoxStyle]}>
          <RadioButton {...register('gender')} label={t('Male')} checked={watchGender === 'M'} name="gender" value="M" />
          <RadioButton {...register('gender')} label={t('Female')} checked={watchGender === 'F'} name="gender" value="F" />
        </div>

        {errors.gender?.message && <ErrorMessage text={errors.gender?.message} />}
      </div>

      <div css={spacerTopStyle}>
        <Input
          required
          {...register('telephone')}
          label={t('Phone number')}
          name="telephone"
          placeholder="0123456789"
          error={errors.telephone?.message}
        />
      </div>

      {watchNationality === 'JPN' && <RHFSearchAddress control={control} name="postalCode" setAddressValue={setAddressValue} />}

      <div css={spacerTopStyle}>
        <Input
          required
          {...register('address')}
          label={t('subsequent addresses')}
          name="address"
          placeholder={t('Street, City, State, Country')}
          error={errors.address?.message}
        />
      </div>

      <div css={spacerTopStyle}>
        <SelectBox {...register('occupation')} label={t('Occupation')} id="occupation" name="occupation" options={occupationOptions()} />
      </div>

      {isEmployeeByOccupation(watchOccupation) && (
        <div css={spacerTopStyle}>
          <Input {...register('company')} label={t('Company')} placeholder="aipass inc." />
          <p css={explanatoryNoteStyle}>{t('If you would like a receipt with your corporate name, please fill it out')}</p>
        </div>
      )}

      {isOtherByOccupation(watchOccupation) && (
        <div css={spacerTopStyle}>
          <Input {...register('otherOccupation')} label={t('Company')} />
          <p css={explanatoryNoteStyle}>{t('If you would like a receipt with your corporate name, please fill it out')}</p>
        </div>
      )}
    </>
  )
}

const birthDateWidth = css({
  div: {
    width: '30%',
    '&:first-of-type': {
      width: '40%',
    },
  },
})

const multipleRadioButtonStyle = css({
  marginTop: '1rem',
})
