import React, { useEffect } from 'react'
import { Button } from '@/components/atoms/button'
import { Input } from '@/components/atoms/input'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { RadioButton } from '@/components/atoms/radio-button'
import { CUSTOM_BORDER, CUSTOM_CARD } from '@/components/template/custom-design-style'
import { SearchReservationInput, SearchType } from '@/features/checkIn/search-reservations-slice'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { formButtonStyle, multipleSelectBoxStyle } from '@/styles/form'
import { css } from '@emotion/react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface SearchProps {
  onSearch: SubmitHandler<SearchReservationInput>
  useFormReturn: UseFormReturn<SearchReservationInput>
}
export const Search: React.FC<SearchProps> = ({ onSearch, useFormReturn }) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    watch,
    resetField,
    formState: { isValid },
  } = useFormReturn

  const searchTypeWatch = watch('searchType')

  useEffect(() => {
    resetField('reservationFamilyName')
    resetField('reservationGivenName')
    resetField('reservationTel')
    resetField('reservationId')
  }, [searchTypeWatch])

  return (
    <>
      <NavigateHeader title={t('Search scheduled reservation')} />
      <p css={leadTextStyle}>{t('Please enter your reservation information')}</p>
      <form onSubmit={handleSubmit(onSearch)} css={pcScreenStyle}>
        <div className={CUSTOM_CARD}>
          <div css={listStyle} className={CUSTOM_BORDER}>
            <RadioButton
              {...register('searchType')}
              radioSize="middle"
              checked={searchTypeWatch === SearchType.Phone}
              customLabelCss={radioButtonStyle}
              value="phone"
              label={t('Phone number of your reservation')}
            />
            {searchTypeWatch === SearchType.Phone && (
              <Input {...register('reservationTel')} placeholder="08012345678" itemCss={inputStyle} />
            )}
          </div>
          <div css={listStyle} className={CUSTOM_BORDER}>
            <RadioButton
              {...register('searchType')}
              radioSize="middle"
              checked={searchTypeWatch === SearchType.ReservationId}
              customLabelCss={radioButtonStyle}
              value="reservationId"
              label={t('Reservation ID')}
            />
            {searchTypeWatch === SearchType.ReservationId && (
              <Input {...register('reservationId')} placeholder="aipass001" itemCss={inputStyle} />
            )}
          </div>
          <div css={listStyle} className={CUSTOM_BORDER}>
            <RadioButton
              {...register('searchType')}
              radioSize="middle"
              checked={searchTypeWatch === SearchType.GuestName}
              value="guestName"
              customLabelCss={radioButtonStyle}
              label={t('reservation name or guest name')}
            />
            {searchTypeWatch === SearchType.GuestName && (
              <div css={[multipleSelectBoxStyle, inputStyle]}>
                <Input {...register('reservationGivenName')} placeholder="姓" />
                <Input {...register('reservationFamilyName')} placeholder="名" />
              </div>
            )}
          </div>
        </div>

        <Button disabled={!isValid} buttonCss={[spacerTopStyle, formButtonStyle]} text={t('Search')} type="submit" />
      </form>
    </>
  )
}

const listStyle = css({
  padding: '1rem 1.5rem',
  '&:last-of-type': {
    border: 'none',
  },
})

const radioButtonStyle = css({
  fontWeight: 'bold',
  marginBottom: 0,
})

const inputStyle = css({
  marginTop: '1rem',
})

const leadTextStyle = css({
  textAlign: 'center',
  fontSize: '14px',
  marginBottom: '1.5rem',
})
