import React from 'react'
import { Layout } from '@/components/template/layout'
import { css } from '@emotion/react'
import { NavigateHeader } from '../atoms/navigate-header'
import { pcScreenStyle } from '@/styles/common'

interface LoginTemplateProps {
  children: React.ReactNode
  navigateTitle: string
  headText: string
}

export const LoginTemplate: React.FC<LoginTemplateProps> = ({ children, navigateTitle, headText }) => {
  return (
    <Layout>
      <NavigateHeader title={navigateTitle} />
      <p css={needIdTextStyle}>{headText}</p>
      <div css={pcScreenStyle}>{children}</div>
    </Layout>
  )
}

const needIdTextStyle = css({
  fontSize: '12px',
  paddingTop: '0.5rem',
  paddingBottom: '1.5rem',
  textAlign: 'center',
  lineHeight: '1.8',
})
