import { useAppSelector } from '@/app/hooks'
import { SpaceAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import React, { useEffect, useMemo, useState } from 'react'
import { CarouselItemWrapper } from '../../atoms/carousel-item-wrapper'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { CUSTOM_BORDER, CUSTOM_LINK, CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import { Divider } from '@/components/atoms/divider'
import { isEmpty } from 'lodash'
import { BorderButton } from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'
import { PullDownIcon } from '@/components/atoms/icon'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'

interface SpaceAssetBlockProps {
  asset: SpaceAssetType
}

const MAX_ITEMS = 3

export const SpaceAssetBlock: React.FC<SpaceAssetBlockProps> = ({ asset }) => {
  const [isShowAllDetail, setIsShowAllDetail] = useState(false)

  const { t } = useTranslation()
  const { smoothScroll } = useSmoothScroll()
  const pickByLanguage = usePickByLanguage()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const design = useAppSelector(state => state.design)

  const spacesByFavorite = asset.details?.filter(space => space.isFavorite)

  const overMaxItems = asset.details?.length > MAX_ITEMS

  const spaces = useMemo(() => {
    if (!asset.details) {
      return []
    }
    return isShowAllDetail ? asset.details : asset.details.slice(0, MAX_ITEMS)
  }, [asset, isShowAllDetail])

  useEffect(() => {
    if (searchParams.get('hash') !== asset.id) {
      return
    }
    setIsShowAllDetail(true)
    smoothScroll(asset.id)
  }, [searchParams])

  return (
    <div id={asset.id} css={spaceBlockStyle} className="custom-design-block">
      <div className="order space-inline">
        <h2 className="block-title" style={{ color: design.theme.brandColor }}>
          {pickByLanguage(asset.title)}
        </h2>
        <BorderButton onClick={() => navigate(`/space/${asset.id}`)} text={t('Reservation from Mobile')} />
      </div>

      {!isEmpty(spacesByFavorite) && (
        <>
          <CarouselItemWrapper
            options={{ align: 'start' }}
            style={{ width: '272px', gap: '1rem' }}
            customStyle={css({
              paddingBottom: '2rem',
              paddingLeft: '1rem',
            })}
          >
            {spacesByFavorite.map(space => (
              <div key={space.id} className="embla__slide">
                <Link to={`/space/${asset.id}/${space.id}`} className={`custom-design-card ${CUSTOM_TEXT_COLOR_LINK} slide-item`}>
                  <img src={space.images[0]} alt={pickByLanguage(space.title)} />

                  <div className="space-inline">
                    <h3 className={`title`}>{pickByLanguage(space.title)}</h3>

                    <p className="price">
                      {!space.spacePrice?.chargePrice?.unitPrice && '¥-'}
                      {space.spacePrice?.chargePrice?.unitPrice &&
                        `${displayYen((space.spacePrice?.chargePrice?.unitPrice || '').toString())}`}
                      {!space.isFixAvailabilityTime && '~'}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </CarouselItemWrapper>

          <div className="space-inline">
            <Divider customCss={{ marginBottom: '2rem' }} />
          </div>
        </>
      )}
      <div className="space-inline">
        {spaces.map(space => {
          return (
            <Link key={space.id} className={`detail-list ${CUSTOM_BORDER}`} to={`/space/${asset.id}/${space.id}`}>
              {space.images[0] ? <img src={space.images[0]} className="thumbnail" /> : <div className="thumbnail" />}
              <div className={`title ${CUSTOM_TEXT_COLOR_LINK}`}>{pickByLanguage(space.title)}</div>
            </Link>
          )
        })}
      </div>
      {overMaxItems && !isShowAllDetail && (
        <div
          className={`show-all-link ${CUSTOM_BORDER}`}
          onClick={() => {
            setIsShowAllDetail(true)
          }}
        >
          <div className={CUSTOM_LINK} style={{ marginRight: 4 }}>
            {t('Show all')}
          </div>
          <PullDownIcon height="20" width="20" />
        </div>
      )}
    </div>
  )
}

const spaceBlockStyle = css({
  paddingBlock: '2rem',
  '.order': {
    marginBottom: '2rem',
  },
  '.space-inline': {
    paddingInline: '1rem',
  },
  '.block-title': {
    marginBottom: '1rem',
    fontSize: 18,
  },
  '.slide-item': {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    img: {
      width: '100%',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '.title': {
      marginTop: '1rem',
      marginBottom: '2rem',
      fontWeight: 'bold',
      lineHeight: '1.31',
    },
    '.price': {
      marginTop: 'auto',
      fontSize: '14px',
      paddingBottom: '1rem',
    },
  },
  '.detail-list': {
    display: 'flex',
    padding: '8px 0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    ':last-of-type': { borderBottom: 'none' },
    '.thumbnail': {
      width: 80,
      minWidth: 80,
      height: 45,
      borderRadius: 5,
    },
    '.title': {
      paddingLeft: 16,
      fontSize: 16,
      lineHeight: '52px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  '.show-all-link': {
    paddingTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    '> img': {
      paddingLeft: 4,
      width: 20,
      height: 20,
    },
  },
})
