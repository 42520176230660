import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { CardDetail, addCreditCard, cardSchema } from '@/features/user/user-card-slice'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PaymentBlock } from '../payment-block'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { errorHandler } from '@/libs/errors'
import { useTranslation } from 'react-i18next'
import { formatCardExpire } from '@/utils/creditCard/card-expire'
import { Button } from '@/components/atoms/button'
import { formButtonStyle } from '@/styles/form'
import {
  AssignSpaceInput,
  SpaceReservationInput,
  createSpaceReservation,
  createSpaceReservationWithoutAuth,
} from '@/features/space/space-reservation-slice'
import dayjs from 'dayjs'
import { useLoginRedirect } from '@/hooks/use-login-redirect'
import { isEmpty } from 'lodash'
import { checkMembershipValid } from '@/features/user/membership-slice'

export const SpacePaymentForm: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const auth = localStorage.getItem('access_token')

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()
  const { assetId, spaceId } = useParams<{ assetId: string; spaceId: string }>()

  const { storeRedirectFromUrl } = useLoginRedirect()

  const userCreditCard = useAppSelector(state => state.creditCard.userCreditCardData)
  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const userData = useAppSelector(state => state.accountInfo.user)
  const { reservationInfo, hasRoomSettingKey } = useAppSelector(state => state.spaceReservation)
  const formValues = useAppSelector(state => state.spaces.formValues)

  // check has payment credit option
  const spacePaymentOption = useMemo(() => {
    return paymentSetting.payment_time?.find(v => v.is_enabled && v.value === 'RESERVE_SPACE')
  }, [paymentSetting.payment_time])

  const schema = z.discriminatedUnion('paymentType', [
    z.object({
      paymentType: z.literal('creditCard'),
      cardNumber: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardNumber,
      cardExpireYear: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireYear,
      cardExpireMonth: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireMonth,
      securityCode: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.securityCode,
      name: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.name,
      receiptName: cardSchema.shape.receiptName,
    }),
    z.object({
      paymentType: z.literal('checkout'),
    }),
  ])

  const createSchema = () => {
    if (!spacePaymentOption || !formValues?.totalAmount) {
      return undefined
    }

    return zodResolver(schema)
  }

  const useFormReturn = useForm<CardDetail>({
    mode: 'all',
    resolver: createSchema(),
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormReturn

  const isNeedLogin = useMemo(() => {
    return isEmpty(reservationInfo) || hasRoomSettingKey || !!(spacePaymentOption && formValues?.totalAmount)
  }, [reservationInfo, spacePaymentOption, formValues])

  const onsubmit: SubmitHandler<CardDetail> = async submitData => {
    if (!formValues) {
      return
    }

    try {
      setLoading(true)

      if (submitData.paymentType === 'creditCard') {
        if (!userCreditCard?.cardId) {
          await dispatch(
            addCreditCard({
              cuicinUserId: userData.id,
              cardDetail: {
                cardNumber: submitData.cardNumber.replace(/ /g, ''),
                cardExpire: formatCardExpire({ cardExpireMonth: submitData.cardExpireMonth, cardExpireYear: submitData.cardExpireYear }),
                securityCode: submitData.securityCode,
              },
            }),
          ).unwrap()
        }
      }

      const name = !isEmpty(userData) ? `${userData.lastName} ${userData.firstName}` : reservationInfo.guestName
      // SpaceReservationInput
      const newReservation: SpaceReservationInput = {
        name,
        nameKana: `${userData.familyNameKana} ${userData.givenNameKana}`,
        tel: userData.telephone,
        email: userData.email,
        address: userData.address,
        checkinDate: dayjs(formValues.useDay).format('YYYY-MM-DD'),
        checkoutDate: dayjs(formValues.useDay).format('YYYY-MM-DD'),
      }

      // AssignSpaceInput
      const assignSpaces: AssignSpaceInput[] = [...Array(Number(formValues.quantity))].map((_, i) => ({
        usageFrom: dayjs(`${formValues.useDay} ${formValues.usageFromHour}:${formValues.usageFromMinute}`).format('YYYY-MM-DD HH:mm:ss'),
        usageTo: dayjs(
          `${formValues.useDay} ${formValues.usageToHour === '00' ? '24' : formValues.usageToHour}:${formValues.usageToMinute}`,
        ).format('YYYY-MM-DD HH:mm:ss'),
        spaceId: formValues.spaceId,
        reservationUserName: name,
        pax: formValues.pax,
        answer: formValues.answer,
        spaceIndex: i + 1,
      }))

      const reserveData = {
        assignSpaces,
        paymentMethodType: submitData.paymentType || 'checkout',
        hotelId,
        receiptName: submitData.receiptName,
      }

      if (reservationInfo.reservationId) {
        if (isNeedLogin) {
          await dispatch(
            createSpaceReservation({
              reservationId: reservationInfo.reservationId,
              ...reserveData,
            }),
          ).unwrap()
        } else {
          await dispatch(
            createSpaceReservationWithoutAuth({
              reservationId: reservationInfo.reservationId,
              ...reserveData,
            }),
          )
        }
      } else {
        await dispatch(
          createSpaceReservation({
            newReservation,
            ...reserveData,
          }),
        ).unwrap()
      }

      navigate(`thanks`)
    } catch (error) {
      errorHandler({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!formValues) {
      navigate(`/space/${assetId}/${spaceId}`)
    }
  }, [])

  useEffect(() => {
    if (isNeedLogin) {
      const redirectUrl = `/space/${spaceId}/application-confirm`
      if (!auth) {
        storeRedirectFromUrl(redirectUrl)
        return navigate('/login?loginType=membership')
      }

      if (!isEmpty(userData) && !checkMembershipValid(userData)) {
        storeRedirectFromUrl(redirectUrl)
        return navigate('/register-membership')
      }
    }
  }, [auth, userData, isNeedLogin])

  return (
    <>
      {formValues?.totalAmount && <PaymentBlock useFormReturn={useFormReturn} paymentPlace="RESERVE_SPACE" />}
      <form onSubmit={handleSubmit(onsubmit)}>
        <div css={[formButtonStyle, { marginTop: '2rem' }]}>
          <Button disabled={!isValid} text={t('Settlement')} type="submit" loading={loading} />
        </div>
      </form>
    </>
  )
}
