import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { Layout } from '@/components/template/layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { ReservationForm, getSpaceAvailableTime, storeFormValues } from '@/features/space/spaces-slice'
import dayjs, { Dayjs } from 'dayjs'
import { globalHotelId } from '@/hooks/use-hotel-id'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BLOCK, CUSTOM_BORDER } from '@/components/template/custom-design-style'
import { v4 as uuid } from 'uuid'
import { Button } from '@/components/atoms/button'
import { CarouselItemWrapper } from '@/components/atoms/carousel-item-wrapper'
import { useTranslation } from 'react-i18next'
import { DateCalendar } from '@/components/atoms/date-calendar'
import { CalendarIcon } from '@/components/atoms/icon'
import { SpacePriceLabel } from '@/components/organisms/space/space-price-label'
import { useBreakPoint } from '@/hooks/use-break-point'
import { minScreen } from '@/styles/media-query'

export const SpaceList: React.FC = () => {
  const { assetId } = useParams<{ assetId: string }>()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const pickByLanguage = usePickByLanguage()
  const { isPc } = useBreakPoint()
  const spaceDetails = useAppSelector(state => state.spaces?.spaceDetails)
  const formValues = useAppSelector(state => state.spaces?.formValues) as ReservationForm
  const [useDay, setUseDay] = useState<Dayjs>(dayjs(formValues?.useDay))
  const [isShowCalender, setIsShowCalender] = useState(false)
  const fetchSpaceList = () => {
    if (!assetId) {
      return
    }
    dispatch(
      getSpaceAvailableTime({
        hotelId: globalHotelId(),
        guestAppAssetId: assetId,
        useDay,
      }),
    )
  }

  const filterSelectableStockTime = (availabilityTimes: {
    [key: string]: number
  }): {
    [key: string]: number
  } => {
    const filteredAvailabilityTimes: { [key: string]: number } = {}
    Object.keys(availabilityTimes)
      .filter((time, i) => i % 4 === 0)
      .filter(time => availabilityTimes[time] > 0)
      .map(time => (filteredAvailabilityTimes[time] = availabilityTimes[time]))
    return filteredAvailabilityTimes
  }

  const hasEnabledTime = (times: { [key: string]: number }): boolean => {
    return Object.keys(times).findIndex(t => times[t] > 0) !== -1
  }

  useEffect(() => {
    if (!isPc) {
      fetchSpaceList()
    }
  }, [useDay, isPc])

  return (
    <Layout>
      <div css={datePickerCardStyle} className={CUSTOM_BLOCK}>
        <div className={`date-label ${isPc ? CUSTOM_BORDER : ''}`}>
          {isShowCalender && (
            <DateCalendar
              onSetValue={date => {
                dispatch(storeFormValues({ ...formValues, useDay: date }))
                setUseDay(dayjs(date))
              }}
              date={useDay.format('YYYY-MM-DD')}
              onSettlement={() => setIsShowCalender(false)}
              onClose={() => setIsShowCalender(false)}
            />
          )}
          <CalendarIcon />
          <label style={{ width: '100%' }} onClick={() => setIsShowCalender(true)}>
            {useDay.format('YYYY/MM/DD')}
          </label>
        </div>

        <Button text={t('Search')} className="search" onClick={() => fetchSpaceList()} />
      </div>

      <div css={spaceItemCardWrapperStyle}>
        {spaceDetails.map(space => (
          <div
            key={space.id}
            className={`space-item-card ${CUSTOM_BLOCK} ${hasEnabledTime(space.availabilityTimes) ? '' : 'disabled'}`}
            onClick={() => {
              if (!hasEnabledTime(space.availabilityTimes)) {
                return
              }

              dispatch(
                storeFormValues({
                  ...formValues,
                  usageFromHour: undefined,
                  usageFromMinute: undefined,
                }),
              )
              navigate(`/space/${assetId}/${space.id}`)
            }}
          >
            <div className="info-block">
              <div style={{ overflow: 'hidden', paddingTop: 2 }}>
                <div className="title-text">{pickByLanguage(space.title)}</div>
                <div>
                  <SpacePriceLabel space={space} />
                </div>
              </div>
              <img src={space.images[0]} alt={pickByLanguage(space.title)} />
            </div>

            <div css={selectableTimeButtonStyle} onClick={e => e.stopPropagation()}>
              {hasEnabledTime(space.availabilityTimes) ? (
                <CarouselItemWrapper
                  options={{ align: 'start', dragFree: true, containScroll: 'trimSnaps' }}
                  style={{ width: '59px', gap: '8px' }}
                >
                  {Object.keys(filterSelectableStockTime(space.availabilityTimes)).map(t => (
                    <div key={uuid()} className="embla__slide time-text" style={{ paddingBottom: 16 }}>
                      <Button
                        className={space.availabilityTimes[t] ? '' : 'disabled'}
                        text={t}
                        onClick={e => {
                          if (!space.availabilityTimes[t]) {
                            return
                          }
                          dispatch(
                            storeFormValues({
                              ...formValues,
                              usageFromHour: t.split(':')[0],
                              usageFromMinute: t.split(':')[1],
                            }),
                          )
                          navigate(`/space/${assetId}/${space.id}`)
                        }}
                      ></Button>
                    </div>
                  ))}
                </CarouselItemWrapper>
              ) : (
                <div style={{ paddingBottom: 16 }}>
                  <Button text={t('There are no spaces available on the dates specified')} className="empty-text"></Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

const datePickerCardStyle = css({
  height: 44,
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  marginBottom: 4,
  [minScreen()]: {
    height: 76,
    justifyContent: 'center',
    gap: 16,
    '.date-label': {
      flex: '0 0 486px',
      height: 44,
      border: '1px solid #CCCCCC',
      borderRadius: 6,
      paddingInline: 14,
    },
  },
  svg: {
    width: 14,
    height: 14,
    marginRight: 16,
  },
  label: {
    fontSize: 14,
    letterSpacing: 1.4,
    fontWeight: '500',
    lineHeight: '44px',
  },
  '.search': {
    display: 'none',
    [minScreen()]: {
      display: 'block',
      flex: '0 0 98px',
      cursor: 'pointer',
    },
  },
})

const spaceItemCardWrapperStyle = css({
  '.space-item-card': {
    '&.disabled, .disabled': {
      opacity: 0.3,
    },
    marginBottom: 8,
    ':last-of-type': {
      marginBottom: 0,
    },
    '.info-block': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 24px 0 22px',
      '.title-text': {
        lineHeight: '24px',
        letterSpacing: 1.6,
        fontSize: 16,
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [minScreen()]: {
          fontSize: 18,
          paddingTop: 18,
        },
      },
      img: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: 5,
      },
      [minScreen()]: {
        flexDirection: 'column-reverse',
        padding: 0,
        '> div': {
          padding: 24,
        },
        img: {
          width: '100%',
          height: 'auto',
          borderRadius: 0,
        },
      },
    },
  },
  [minScreen()]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 17,
    maxWidth: 1000,
    margin: '30px auto 22px',
    '.space-item-card': {
      flex: '0 0 322px',
      borderRadius: 10,
      overflow: 'hidden',
    },
    '.embla__container': {
      flexWrap: 'wrap',
    },
  },
})

const selectableTimeButtonStyle = css({
  padding: 16,
  paddingBottom: 0,
  [minScreen()]: {
    paddingTop: 0,
  },
  button: {
    height: '31px !important',
  },
  '.time-text button': {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1.4,
  },
  '.empty-text button': {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1.2,
  },
})
