import React, { useMemo } from 'react'
import { useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SpacePaymentForm } from '@/components/organisms/space/space-payment-form'
import { PaymentFormTemplate } from '@/components/template/payment-form-template'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import dayjs from 'dayjs'
import { FormLayout } from '@/components/template/form-layout'
import { Divider } from '@/components/atoms/divider'
import { css } from '@emotion/react'
import { displayYen } from '@/libs/text'
import { TotalAmount } from '@/components/atoms/total-amount'
import { ContentWrapper } from '@/components/template/content-wrapper'

export const SpaceConfirmation: React.FC = () => {
  const { t } = useTranslation()

  const pickByLanguage = usePickByLanguage()

  const { spaceId } = useParams<{ spaceId: string }>()

  const { spaceDetails, formValues } = useAppSelector(state => state.spaces)
  const spaceInfo = useMemo(() => spaceDetails.find(detail => detail.id === spaceId), [spaceDetails])

  const createDisplayTime = (props: { hour: string; minute: string }) => {
    return `${props.hour}:${props.minute}`
  }

  return (
    <Layout>
      <NavigateHeader title={t('Payment details')} />
      <ContentWrapper>
        {spaceInfo && formValues && (
          <FormLayout>
            <h3 css={spaceTypeNameStyle}>{pickByLanguage(spaceInfo.title)}</h3>
            <p css={usageInfoStyle}>
              {`${dayjs(formValues.useDay).format('YYYY/MM/DD')}
              ${createDisplayTime({
                hour: formValues.usageFromHour,
                minute: formValues.usageFromMinute,
              })}~${createDisplayTime({ hour: formValues.usageToHour, minute: formValues.usageToMinute })} - ${formValues.quantity}${pickByLanguage(
                spaceInfo?.stockUnit,
              )}`}
            </p>
            <p css={priceStyle}>{formValues.totalAmount ? displayYen(formValues.totalAmount) : '-'}</p>

            <Divider customCss={{ marginBlock: 16 }} />

            <div css={usageStyle}>
              <p>
                {t('Requests from Facilities')}：{formValues.answer || '-'}
              </p>
              <p>
                {`${t('Number of users')}：
                ${formValues.pax}
                ${t('Guest')}`}
              </p>
            </div>
          </FormLayout>
        )}
        {formValues?.totalAmount && (
          <TotalAmount totalAmount={formValues.totalAmount} customCss={css({ marginTop: 8, marginBottom: -16 })} />
        )}
        <PaymentFormTemplate>
          <SpacePaymentForm />
        </PaymentFormTemplate>
      </ContentWrapper>
    </Layout>
  )
}

const spaceTypeNameStyle = css({
  fontSize: 16,
  fontWeight: 'bold',
})

const usageInfoStyle = css({
  marginTop: 8,
  marginBottom: 12,
  fontSize: 12,
})

const priceStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
})

const usageStyle = css({
  p: {
    fontSize: 12,
    lineHeight: 1.75,
  },
})
