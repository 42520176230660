import { SerializedStyles, css } from '@emotion/react'
import React, { MouseEventHandler } from 'react'
import { Loading } from '../organisms/loading'
import { CUSTOM_BUTTON } from '../template/custom-design-style'

interface ButtonProps {
  text: string
  onClick?: MouseEventHandler<HTMLDivElement>
  type?: 'submit' | 'button'
  buttonCss?: SerializedStyles | SerializedStyles[]
  className?: string
  disabled?: boolean
  loading?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  disabled = false,
  type = 'button',
  text,
  buttonCss,
  onClick,
  loading = false,
  className,
}) => {
  return (
    <div className={className ?? ''} css={[buttonWrapStyle, buttonCss]} onClick={onClick}>
      <button className={CUSTOM_BUTTON} type={type} disabled={disabled}>
        <Loading size={32} loading={loading}>
          {text}
        </Loading>
      </button>
    </div>
  )
}

export const BorderButton: React.FC<ButtonProps> = ({ disabled = false, type = 'button', text, buttonCss, onClick, loading = false }) => {
  return (
    <div css={[buttonWrapStyle, buttonCss]} onClick={onClick}>
      <button className={`${CUSTOM_BUTTON}-secondary`} type={type} disabled={disabled}>
        <Loading size={32} loading={loading}>
          {text}
        </Loading>
      </button>
    </div>
  )
}

const buttonWrapStyle = css({
  width: '100%',
  textAlign: 'center',
  '> button': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '44px',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.3,
    },
  },
})
