import React, { useMemo } from 'react'
import { useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { ItemLabel } from '@/components/molecules/item-label'
import { Layout } from '@/components/template/layout'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '@/components/template/form-layout'
import { convertedOccupationValueToLabel } from '@/utils/form/occupation'
import dayjs from 'dayjs'
import { css } from '@emotion/react'
import { pcScreenStyle } from '@/styles/common'

export const MemberInfo: React.FC = () => {
  const { t } = useTranslation()

  const userData = useAppSelector(state => state.accountInfo.user)

  const userDataItems = useMemo(() => {
    return [
      {
        name: 'Nationality',
        data: t(userData?.nationality === 'NJP' ? 'non-Japanese' : 'Japanese'),
      },
      {
        name: 'Full name',
        data: userData?.name,
      },
      {
        name: 'Date of birth',
        data: userData?.birthDate ? dayjs(userData.birthDate).format('YYYY/MM/DD') : '',
      },
      {
        name: 'Gender',
        data: userData?.gender === 'M' ? t('Male') : t('Female'),
      },
      {
        name: 'Phone number',
        data: userData?.telephone,
      },
      {
        name: 'Address',
        data: userData?.address,
      },
      {
        name: 'Occupation',
        data: convertedOccupationValueToLabel({
          occupation: userData.occupation,
          otherOccupation: userData.otherOccupation,
          company: userData.company,
        }),
      },
    ]
  }, [userData])

  return (
    <Layout>
      <NavigateHeader title={t('Member Information')} />

      <FormLayout layoutCss={pcScreenStyle}>
        <div css={itemLabelBlockStyle}>
          {userData && userDataItems.map((item, index) => <ItemLabel key={index} label={t(item.name)} value={item.data} />)}
        </div>
      </FormLayout>
    </Layout>
  )
}

const itemLabelBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
})
