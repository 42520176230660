import React, { useEffect, useMemo } from 'react'
import { ReservationForm, SpaceDetail, getStartAvailabilityTimes, timeToMinutes } from '@/features/space/spaces-slice'
import dayjs from 'dayjs'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { SelectBox } from '@/components/atoms/select-box'
import { css } from '@emotion/react'

type Props = {
  space: SpaceDetail
  control: Control<ReservationForm, any>
  setValue: UseFormSetValue<ReservationForm>
  watchValues: {
    quantity: number
    useDay: string
    usageFromHour: string
    usageFromMinute: string
    usageToHour: string
    usageToMinute: string
  }
}

export const ReserveTimeBox: React.FC<Props> = ({ space, control, setValue, watchValues }) => {
  const makeHourOptions = (times: string[]): { label: string; value: string }[] => {
    if (!space) {
      return []
    }
    const pickedValue: string[] = []
    return times
      .map(time => ({
        label: time.split(':')[0],
        value: time.split(':')[0],
      }))
      .filter(opt => {
        if (pickedValue.includes(opt.value)) {
          return false
        }
        pickedValue.push(opt.value)
        return true
      })
  }

  const makeSelectMinutesOption = (times: string[], hour: string) => {
    return (times || [])
      .filter(d => d.split(':')[0] === hour)
      .map(d => {
        const item = d.split(':')[1]
        return { label: item, value: item }
      })
  }

  const minUsageMinute = useMemo(() => timeToMinutes(space.spacePrice?.chargePrice.usageTime.start), [space])

  const startAvailabilityTimes = useMemo((): string[] => {
    if (!space) {
      return []
    }
    return getStartAvailabilityTimes(space.availabilityTimes, watchValues.quantity, minUsageMinute)
  }, [space?.availabilityTimes, watchValues.quantity])

  const endAvailabilityTimes = useMemo((): string[] => {
    if (!space) {
      return []
    }
    let firsStartTime: string
    firsStartTime = Object.keys(space.availabilityTimes || {})[0]
    if (watchValues.usageFromHour && watchValues.usageFromMinute) {
      firsStartTime = `${watchValues.usageFromHour}:${watchValues.usageFromMinute}`
    }
    const firstStartTimeAtDayjs = dayjs(watchValues.useDay)
      .set('hour', Number(firsStartTime.split(':')[0]))
      .set('minute', Number(firsStartTime.split(':')[1]))

    // 終了は在庫時間を15分ずらしたものを使用する
    // e.g.)10:45の在庫を11:00に適用
    let mappedTimes = Object.keys(space.availabilityTimes).map(time => ({
      time: dayjs(`${watchValues.useDay} ${time}`).add(15, 'minute').format('HH:mm'),
      stock: space.availabilityTimes[time],
    }))

    const minUsageTime = firstStartTimeAtDayjs.add(minUsageMinute, 'minute').format('HH:mm')
    const minUsageTimeIndex = mappedTimes.findIndex(({ time }) => minUsageTime === time)
    if (minUsageTimeIndex === -1) {
      return []
    }
    mappedTimes = mappedTimes.slice(minUsageTimeIndex)

    if (space.spacePrice?.chargePrice.usageTime.end) {
      const maxUsageMinute = timeToMinutes(space.spacePrice.chargePrice.usageTime.end)
      const maxUsageTime = firstStartTimeAtDayjs.add(maxUsageMinute, 'minute').format('HH:mm')
      const maxUsageTimeIndex = mappedTimes.findIndex(({ time }) => maxUsageTime === time)
      if (maxUsageTimeIndex !== -1) {
        mappedTimes = mappedTimes.slice(0, maxUsageTimeIndex + 1)
      }
    }

    let overstockTimeIndex = mappedTimes.findIndex(({ stock }) => stock < watchValues.quantity)
    if (overstockTimeIndex === -1) {
      overstockTimeIndex = mappedTimes.length
    }

    return mappedTimes.slice(0, overstockTimeIndex).map(({ time }) => time)
  }, [startAvailabilityTimes, watchValues.usageFromHour, watchValues.usageFromMinute, watchValues.quantity])

  useEffect(() => {
    if (!startAvailabilityTimes[0]) {
      return
    }
    if (!startAvailabilityTimes.includes(`${watchValues.usageFromHour || '00'}:${watchValues.usageFromMinute || '00'}`)) {
      const changedHourIndex = startAvailabilityTimes.findIndex(time => time.includes(`${watchValues.usageFromHour || '00'}:`))
      if (changedHourIndex !== -1) {
        setValue('usageFromHour', startAvailabilityTimes[changedHourIndex].split(':')[0])
        setValue('usageFromMinute', startAvailabilityTimes[changedHourIndex].split(':')[1])
      } else {
        setValue('usageFromHour', startAvailabilityTimes[0].split(':')[0])
        setValue('usageFromMinute', startAvailabilityTimes[0].split(':')[1])
      }
    }
  }, [startAvailabilityTimes, watchValues.usageFromHour])

  useEffect(() => {
    if (!endAvailabilityTimes[0]) {
      return
    }
    if (!endAvailabilityTimes.includes(`${watchValues.usageToHour || '00'}:${watchValues.usageToMinute || '00'}`)) {
      if (Math.max(...endAvailabilityTimes.map(t => Number(t.split(':')[0]))) <= Number(watchValues.usageToHour)) {
        setValue('usageToHour', endAvailabilityTimes[endAvailabilityTimes.length - 1].split(':')[0])
        setValue('usageToMinute', endAvailabilityTimes[endAvailabilityTimes.length - 1].split(':')[1])
      } else {
        setValue('usageToHour', endAvailabilityTimes[0].split(':')[0])
        setValue('usageToMinute', endAvailabilityTimes[0].split(':')[1])
      }
    }
  }, [endAvailabilityTimes, watchValues.usageToHour, watchValues.usageToMinute])

  return (
    <>
      <Controller
        control={control}
        name="usageFromHour"
        render={({ field }) => (
          <SelectBox {...field} itemCss={selectStyle} options={makeHourOptions(startAvailabilityTimes)} arrowDefaultSelect={false} />
        )}
      />
      :
      <Controller
        control={control}
        name="usageFromMinute"
        render={({ field }) => (
          <SelectBox
            {...field}
            itemCss={selectStyle}
            options={makeSelectMinutesOption(startAvailabilityTimes, watchValues.usageFromHour)}
            arrowDefaultSelect={false}
          />
        )}
      />
      ~
      <Controller
        control={control}
        name="usageToHour"
        render={({ field }) => (
          <SelectBox
            {...field}
            itemCss={selectStyle}
            disabled={space.isFixAvailabilityTime || makeHourOptions(endAvailabilityTimes).length === 0}
            options={makeHourOptions(endAvailabilityTimes)}
            arrowDefaultSelect={false}
          />
        )}
      />
      :
      <Controller
        control={control}
        name="usageToMinute"
        render={({ field }) => (
          <SelectBox
            {...field}
            itemCss={selectStyle}
            disabled={space.isFixAvailabilityTime || makeHourOptions(endAvailabilityTimes).length === 0}
            options={makeSelectMinutesOption(endAvailabilityTimes, watchValues.usageToHour)}
            arrowDefaultSelect={false}
          />
        )}
      />
    </>
  )
}
const selectStyle = css({
  '.select-wrapper': { marginTop: 0 },
  select: { padding: '10px 16px' },
})
