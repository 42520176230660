import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { DetailsTableBlock } from '@/components/organisms/invoice/details-table-block'
import { Loading } from '@/components/organisms/loading'
import { FormLayout } from '@/components/template/form-layout'
import { Layout } from '@/components/template/layout'
import { AccountInfo } from '@/features/user/account-slice'
import { getInvoiceDetails } from '@/features/user/payment/get-user-invoice-slice'
import { displayYen, translateObject } from '@/libs/text'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Divider } from '@/components/atoms/divider'
import { blockSpaceTop } from '@/styles/common'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { ContentWrapper } from '@/components/template/content-wrapper'

export const InvoiceInfo: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const dispatch = useAppDispatch()

  const params = useParams()

  const userData = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const { pdfInfo, salesList, total, loading } = useAppSelector(state => state.userPayment.getInvoiceDetails)

  useEffect(() => {
    if (userData.id && params.orderId) {
      dispatch(getInvoiceDetails({ cuicinUserId: userData.id, orderId: params.orderId }))
    }
  }, [userData])

  return (
    <Layout>
      <NavigateHeader title={t('Invoice')} />
      <Loading loading={loading}>
        <ContentWrapper>
          <FormLayout>
            <div css={receiptBlock}>
              <p>{pdfInfo.name}様</p>
              <p>
                <span>
                  {t('Date of issue')}：{pdfInfo.date}
                </span>
                <span>No. {pdfInfo.receiptNumber}</span>
              </p>
            </div>

            <div css={paymentDetailStyle}>
              <p>{t('Company Information')}：</p>
              <p>
                <span>{pdfInfo.hotelName}</span>
                <span>{pdfInfo.hotelAddress}</span>
                <span>{pdfInfo.hotelTelephone}</span>
              </p>
            </div>

            <div css={paymentDetailStyle} style={{ marginTop: '4px' }}>
              <p>{t('Registration number')}：</p>
              <p>
                <span>{pdfInfo.hotelRegistrationNumber || '-'}</span>
              </p>
            </div>
            <div css={paymentDetailStyle} style={{ marginTop: '4px' }}>
              <p>{t('Transaction Number')}：</p>
              <p>
                <span>{params.orderId}</span>
              </p>
            </div>
          </FormLayout>

          <DetailsTableBlock
            sales={salesList.map(sale => ({
              salesDate: sale.salesDate,
              salesPrice: sale.salesPrice,
              salesSubjectName: translateObject(sale, 'salesSubjectName', language),
            }))}
            totalPrice={total.totalSalesPrice}
            totalDiscount={total.totalSalesDiscount}
          />

          <div css={totalAmountBlockStyle} className={CUSTOM_BLOCK}>
            <Divider customCss={{ marginBottom: '20px' }} />
            <div css={totalAmountStyle}>
              <div>{t('Total Amount(tax included)')}</div>
              <div>{displayYen(String(total?.totalSalesPrice - total?.totalSalesDiscount))}</div>
            </div>
          </div>

          {/* receipt */}
          <FormLayout layoutCss={blockSpaceTop}>
            <div css={receiptBlock}>
              <p>{t('Receipt')}</p>
              <p>
                <span>
                  {t('Date of issue')}：{pdfInfo.date}
                </span>
                <span>No. {pdfInfo.receiptNumber}</span>
              </p>
            </div>

            <div css={paymentDetailStyle}>
              <p>
                <span>{pdfInfo.hotelName}</span>
                <span>{pdfInfo.hotelAddress}</span>
                <span>{pdfInfo.hotelTelephone}</span>
                <span>
                  {t('Registration number')}：{pdfInfo.hotelRegistrationNumber || '-'}
                </span>
              </p>
            </div>

            <div css={receiptList}>
              <p css={receiptListItem}>
                <span>{t('Name')}</span>
                <span>{pdfInfo.name}様</span>
              </p>
              <p css={receiptListItem}>
                <span>{t('Total Amount')}</span>
                <span>{displayYen(String(total?.totalSalesPrice - total?.totalSalesDiscount))}</span>
              </p>
              <p css={receiptListItem}>
                <span>{t('tax')}</span>
                <span>{displayYen(String(total?.totalReceipt8TaxPrice + total?.totalReceipt10TaxPrice))}</span>
              </p>
              <p css={noticeStyle}>{t('The above amount has been received.(tax included)')}</p>
              <p css={receiptListItem}>
                <span>{t('In payment of')}</span>
                <span></span>
              </p>
            </div>
          </FormLayout>
        </ContentWrapper>
      </Loading>
    </Layout>
  )
}

const receiptBlock = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  p: {
    lineHeight: '1.4',
    '&:first-of-type': {
      fontWeight: 'bold',
      letterSpacing: '2.4px',
    },
    '&:last-of-type': {
      fontSize: '10px',
      textAlign: 'right',
      span: {
        display: 'block',
      },
    },
  },
})

const paymentDetailStyle = css({
  display: 'flex',
  fontSize: '10px',
  lineHeight: '1.4',
  span: {
    display: 'block',
  },
})

const totalAmountBlockStyle = css({
  paddingRight: '2rem',
  paddingLeft: '2rem',
  paddingBottom: '2rem',
})

const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  div: {
    '&:first-of-type': {
      fontSize: '14px',
    },
    '&:last-child': {
      fontSize: '18px',
      textAlign: 'right',
    },
  },
})

const receiptList = css({
  marginTop: '1.5rem',
})

const receiptListItem = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #272727',
  fontWeight: 'bold',
  paddingBottom: '6px',
  marginBottom: '1rem',
  '&:last-of-type': {
    marginBottom: 0,
  },
  span: {
    fontSize: '12px',
    '&:last-of-type': {
      fontSize: '14px',
    },
  },
})

const noticeStyle = css({
  fontSize: '10px',
  marginTop: '-0.25rem',
  marginBottom: '1rem',
  letterSpacing: '1.5px',
})
