import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getStayReservations = createAsyncThunk('stay-reservation/list', async (params: { hotelId: string; telephone: string }) => {
  const reservationResponse = await http({
    method: 'get',
    url: `${env('BASE_API_V3')}/stay-reservation`,
    params,
  })
  return reservationResponse.data.stayReservations
})

export interface StayReservation {
  checkinId: string
  checkinDate: string
  checkoutDate: string
  frontSupported: boolean
  guestName: string
  reservationUserName: string
  nights: number
  plan: string
  planEn: string
  reservationId: string
  roomNumbers: string[]
  assignRooms: Array<{
    typeId: string
    name: string
    roomNumber: string
  }>
}

const stayReservationsSlice = createSlice({
  name: 'stayReservations',
  initialState: {
    stayReservations: [] as StayReservation[],
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getStayReservations.fulfilled, (state, action: PayloadAction<StayReservation[]>) => {
      state.stayReservations = action.payload
    })
  },
})

export const stayReservationsReducer = stayReservationsSlice.reducer
