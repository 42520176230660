import React, { useMemo } from 'react'
import { ReactComponent as RCPulldownIcon } from '@/static/images/icon/icon_pulldown.svg'
import { ReactComponent as RCMenuHamburgerIcon } from '@/static/images/icon/icon_menu_hamburger.svg'
import { ReactComponent as RCMenuDeleteIcon } from '@/static/images/icon/icon_menu_deleat.svg'
import { ReactComponent as RCLanguageIcon } from '@/static/images/icon/icon_language.svg'
import { ReactComponent as RCExclamationIcon } from '@/static/images/exclamation.svg'
import { ReactComponent as RCErrorDeleteIcon } from '@/static/images/icon/error_delete.svg'
import { ReactComponent as RCEditIcon } from '@/static/images/icon/edit.svg'
import { ReactComponent as RCOkImage } from '@/static/images/image_ok.svg'
import { ReactComponent as RCNgImage } from '@/static/images/image_ng.svg'
import { ReactComponent as RCRightIcon } from '@/static/images/controls/arrow_right.svg'
import { ReactComponent as RCLeftIcon } from '@/static/images/controls/arrow_left.svg'
import { ReactComponent as RCAccountIcon } from '@/static/images/navbar/account_icon.svg'
import { ReactComponent as RCCheckInIcon } from '@/static/images/navbar/checkin_icon.svg'
import { ReactComponent as RCCardIcon } from '@/static/images/navbar/card_icon.svg'
import { ReactComponent as RCInvoiceIcon } from '@/static/images/navbar/invoice_icon.svg'
import { ReactComponent as RCHelpIcon } from '@/static/images/navbar/help_icon.svg'
import { ReactComponent as RCTermsOfUseIcon } from '@/static/images/navbar/terms-of-use_icon.svg'
import { ReactComponent as RCPrivacyPolicyIcon } from '@/static/images/navbar/privacy-policy_icon.svg'
import { ReactComponent as RCSecurityIcon } from '@/static/images/navbar/security_icon.svg'
import { ReactComponent as RCCalendarIcon } from '@/static/images/icon/calendar.svg'
import { CUSTOM_ICON } from '../template/custom-design-style'
import { css } from '@emotion/react'
import { useAppSelector } from '@/app/hooks'

type SVGComponent = React.FC<React.SVGProps<SVGSVGElement>>
export const PullDownIcon: SVGComponent = props => <RCPulldownIcon className={CUSTOM_ICON} {...props} />
export const MenuDeleteIcon: SVGComponent = props => <RCMenuDeleteIcon className={CUSTOM_ICON} {...props} />
export const MenuHamburgerIcon: SVGComponent = props => <RCMenuHamburgerIcon className={CUSTOM_ICON} {...props} />
export const LanguageIcon: SVGComponent = props => <RCLanguageIcon className={CUSTOM_ICON} {...props} />
export const ExclamationIcon: SVGComponent = props => <RCExclamationIcon className={CUSTOM_ICON} {...props} />
export const ErrorDeleteIcon: SVGComponent = props => <RCErrorDeleteIcon className={CUSTOM_ICON} {...props} />
export const EditIcon: SVGComponent = props => <RCEditIcon className={CUSTOM_ICON} {...props} />
export const OkImage: SVGComponent = props => <RCOkImage className={CUSTOM_ICON} {...props} />
export const NgImage: SVGComponent = props => <RCNgImage className={CUSTOM_ICON} {...props} />
export const RightIcon: SVGComponent = props => <RCRightIcon className={CUSTOM_ICON} {...props} />
export const LeftIcon: SVGComponent = props => <RCLeftIcon className={CUSTOM_ICON} {...props} />
export const AccountIcon: SVGComponent = props => <RCAccountIcon className={CUSTOM_ICON} {...props} />
export const CheckInIcon: SVGComponent = props => <RCCheckInIcon className={CUSTOM_ICON} {...props} />
export const CalendarIcon: SVGComponent = props => <RCCalendarIcon className={CUSTOM_ICON} {...props} />
export const CardIcon: SVGComponent = props => <RCCardIcon className={CUSTOM_ICON} {...props} />
export const InvoiceIcon: SVGComponent = props => <RCInvoiceIcon className={CUSTOM_ICON} {...props} />
export const HelpIcon: SVGComponent = props => <RCHelpIcon className={CUSTOM_ICON} {...props} />
export const TermsOfUseIcon: SVGComponent = props => <RCTermsOfUseIcon className={CUSTOM_ICON} {...props} />
export const PrivacyPolicyIcon: SVGComponent = props => <RCPrivacyPolicyIcon className={CUSTOM_ICON} {...props} />
export const SecurityIcon: SVGComponent = props => <RCSecurityIcon className={CUSTOM_ICON} {...props} />
export const PlusIcon: React.FC = () => {
  const design = useAppSelector(state => state.design)
  const style = useMemo(
    () =>
      css({
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: 1,
        width: '12px',
        height: '2px',
        background: design?.icon.color,
        borderRadius: '0.1em',
        position: 'relative',
        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'inherit',
          transform: 'rotate(90deg)',
        },
      }),
    [design],
  )
  return <span css={style}></span>
}
