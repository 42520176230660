import React from 'react'
import { EmailLoginBlock } from '@/components/organisms/email-login-block'
import { LoginTemplate } from '@/components/template/login-template'
import { useTranslation } from 'react-i18next'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'

export const EmailLogin: React.FC = () => {
  const { t } = useTranslation()

  const { loginType } = useQuerySearchParams<{ loginType?: string }>()
  const headText = t(loginType ? 'Membership registration is required to make a reservation' : 'Need your aipassID to sign in')

  return (
    <>
      <LoginTemplate navigateTitle={t('Password')} headText={headText}>
        <EmailLoginBlock />
      </LoginTemplate>
    </>
  )
}
