import React, { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Input } from '../atoms/input'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '../template/form-layout'
import { Button } from '../atoms/button'
import { spacerTopStyle } from '@/styles/common'
import { Divider } from '../atoms/divider'
import { css } from '@emotion/react'
import { useAppDispatch } from '@/app/hooks'
import { checkEmail, forgotPassword } from '@/features/auth/auth-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { socialSignUp } from '@/apis/auth0'
import { LineConfirmModal } from './line-confirm-modal'

import { ReactComponent as FacebookLogo } from '@/static/images/icon/facebook.svg'
import { ReactComponent as GoogleLogo } from '@/static/images/icon/google.svg'
import { errorHandler } from '@/libs/errors'
import { ResetPasswordModal } from './modal/reset-password-modal'
import { CUSTOM_BLOCK, CUSTOM_LINK } from '../template/custom-design-style'
import { useLoginRedirect } from '@/hooks/use-login-redirect'

interface LoginBlockProps {
  children?: React.ReactNode
  redirectFrom?: string
  redirectParams?: string
}

export const LoginBlock: React.FC<LoginBlockProps> = ({ children, redirectFrom, redirectParams }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [mailStatus, setMailStatus] = useState('')
  const { storeRedirectFromUrl } = useLoginRedirect()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const navigate = useNavigate()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const dispatch = useAppDispatch()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<{ email: string }>({
    mode: 'onBlur',
    resolver: zodResolver(
      z.object({
        email: z.string().email().min(1),
      }),
    ),
  })

  const watchEmail = watch('email')

  const onSubmit: SubmitHandler<{ email: string }> = async submitData => {
    // check email
    try {
      const result = await dispatch(checkEmail(submitData.email)).unwrap()

      switch (result.status) {
        case 'reset': {
          const data = {
            email: submitData.email,
            hotelId,
            language: language,
          }
          await dispatch(forgotPassword(data)).unwrap()
          setMailStatus(result.status)
          setIsResetPasswordModalOpen(true)
          return
        }
        default:
          redirectFrom && storeRedirectFromUrl(redirectFrom, redirectParams)
          navigate({
            pathname: '/login-email',
            search: createSearchParams({ hotelId, email: submitData.email, userStatus: result.status }).toString(),
          })
          break
      }
    } catch (error) {
      errorHandler({ error })
    }

    return
  }

  const onSocialSignUp = async (type: string) => {
    await socialSignUp(type)
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  const onLineClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <div id="login">
        {children && <div className={CUSTOM_BLOCK}>{children}</div>}
        <FormLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register('email')}
              name="email"
              label={t('email address')}
              placeholder={t('email address')}
              error={errors.email?.message}
            />
            <Button disabled={!isValid || !isDirty} buttonCss={spacerTopStyle} type="submit" text={t('Next')} />
          </form>
        </FormLayout>
        <Divider />

        <FormLayout>
          <p css={snsTextStyle}>{t('Or check in with your social networking account')}</p>

          <div css={snsButtonWrapStyle}>
            <button css={[snsButtonStyle, facebookButtonStyle]} onClick={() => onSocialSignUp('facebook')}>
              <FacebookLogo />
              <span>Facebook</span>
            </button>
            <button css={[spacerTopStyle, snsButtonStyle, googleButtonStyle]} onClick={() => onSocialSignUp('google-oauth2')}>
              <GoogleLogo />
              <span>Google</span>
            </button>

            <Divider />
          </div>

          <p css={[spacerTopStyle, lineText]} className={CUSTOM_LINK} onClick={() => onLineClick()}>
            {t('Guest who have logged-in on LINE')}
          </p>
        </FormLayout>
      </div>
      <LineConfirmModal onSocialSignUp={onSocialSignUp} isOpen={isModalOpen} onClose={onCloseModal} />
      <ResetPasswordModal
        mailStatus={mailStatus}
        email={watchEmail}
        isOpen={isResetPasswordModalOpen}
        onClose={() => setIsResetPasswordModalOpen(false)}
      />
    </>
  )
}

const snsButtonWrapStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
})

const snsTextStyle = css({
  fontSize: '12px',
  textAlign: 'center',
  marginBottom: '1.5rem',
})

const snsButtonStyle = css({
  width: '100%',
  height: '44px',
  position: 'relative',
  borderRadius: '6px',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  background: 'transparent',
  svg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '1rem',
    margin: 'auto',
  },
})

const facebookButtonStyle = css({
  span: { color: '#1877F2' },
  border: '1px solid #1877F2',
})

const googleButtonStyle = css({
  span: { color: '#676767' },
  border: '1px solid #676767',
})

const lineText = css({
  fontSize: '12px',
  textAlign: 'center',
})
