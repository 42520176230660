import React, { useEffect } from 'react'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { CardInfo } from '@/components/organisms/card/card-info'
import { Layout } from '@/components/template/layout'
import { getCreditCard } from '@/features/user/user-card-slice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Loading } from '@/components/organisms/loading'
import { getAccountInfo } from '@/features/user/account-slice'
import { pcScreenStyle } from '@/styles/common'

export const CreditCard: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const userData = useAppSelector(state => state.accountInfo.user)

  const loading = useAppSelector(state => state.creditCard.getLoading)

  useEffect(() => {
    if (!userData.id) {
      dispatch(getAccountInfo())
    }

    if (userData.id) {
      dispatch(getCreditCard(userData.id))
    }
  }, [userData])

  return (
    <Layout>
      <NavigateHeader title={t('Credit Card')} />
      <Loading loading={loading}>
        <div css={pcScreenStyle}>
          <CardInfo showNoCard />
        </div>
      </Loading>
    </Layout>
  )
}
