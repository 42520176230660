import { Middleware, configureStore } from '@reduxjs/toolkit'
import { CheckInData } from '@/features/checkIn/check-in-slice'
import { AccountInfo, accountInfoReducer } from '@/features/user/account-slice'
import { paymentSettingReducer } from '@/features/hotel/payment-setting-by-hotel'
import { customFieldsReducer } from '@/features/checkIn/custom-check-in-field-slice'
import { hotelGuideReducer } from '@/features/hotel/hotel-guide-slice'
import { creditCardSliceReducer } from '@/features/user/user-card-slice'
import { smartCheckInReducer } from '@/features/checkIn/smart-check-in-slice'
import { toastReducer } from '@/features/notification/toast-slice'
import { assetsReducer } from '@/features/asset/assets-slice'
import { designReducer } from '@/features/asset/design-slice'
import { getUserCheckInDetailsReducer, getUserCheckInHistoryListReducer } from '@/features/user/check-in-history-slice'
import { paymentReducer } from '@/features/user/payment/index-slice'
import { reservationReducer } from '@/features/reservation/index-slice'
import { authReducer } from '@/features/auth'
import { checkInReducer } from '@/features/checkIn'
import { checkoutReducer } from '@/features/checkout'
import { onlyForDev } from '@/utils/check-environment'
import { requestReducer } from '@/features/request'
import { couponReducer } from '@/features/coupon/coupon-slice'
import { spacesReducer } from '@/features/space/spaces-slice'
import { spaceReservationReducer } from '@/features/space/space-reservation-slice'
import { membershipReducer } from '@/features/user/membership-slice'
import { searchReservationSpaceReducer } from '@/features/space/space-search-reservation-slice'

export type CheckInEditFormType = CheckInData & AccountInfo

const throwMiddleware: Middleware = () => next => action => {
  const act = next(action)
  if (act?.error?.name === 'ConditionError') {
    delete act['error']
    return act
  }
  return act
}

export const store = configureStore({
  reducer: {
    request: requestReducer,
    userPayment: paymentReducer,
    userCheckInHistoryList: getUserCheckInHistoryListReducer,
    userCheckInDetails: getUserCheckInDetailsReducer,
    hotelGuide: hotelGuideReducer,
    customFields: customFieldsReducer,
    checkIn: checkInReducer,
    checkout: checkoutReducer,
    reservation: reservationReducer,
    accountInfo: accountInfoReducer,
    membershipInfo: membershipReducer,
    paymentSetting: paymentSettingReducer,
    creditCard: creditCardSliceReducer,
    smartCheckIn: smartCheckInReducer,
    auth: authReducer,
    toast: toastReducer,
    assets: assetsReducer,
    design: designReducer,
    coupon: couponReducer,
    spaces: spacesReducer,
    spaceReservation: spaceReservationReducer,
    searchReservationSpace: searchReservationSpaceReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(throwMiddleware),
  devTools: onlyForDev,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
